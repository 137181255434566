import ShippingAddress from './ShippingAddress';
import ShipAddressModal from './ShipAddressModal';
import {Card, Button} from 'react-bootstrap';
import { useState } from 'react';
import { useAuth } from "../contexts/AuthContext";


const ShippingAddressList = ({ShippingAddresses, onAddressAdded, onError}) => {

    const { token } = useAuth();
    const [showAddrModal, setShowAddrModal] =  useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editAddr, setEditAddr] = useState({});

    const handleShipAddrSubmit = (data) => {
        setShowAddrModal(false)

        if (editMode) {
            fetch(`/api/ship-addr/${data.id}`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `kaToken ${token}`,
              },
              body: JSON.stringify(data),
            })
              .then((res) => {
                if (res.ok) return res.json();
                onError();
              })
              .then((data) => {
                if (data.code === 200) {
                  onAddressAdded();
                } else onError();
              });
        } else {
            fetch("/api/ship-addr", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `kaToken ${token}`,
              },
              body: JSON.stringify(data),
            })
              .then((res) => {
                if (res.ok) return res.json();
                onError();
              })
              .then((data) => {
                if (data.code === 200) {
                  onAddressAdded();
                } else onError();
              });
        }

    }

    const handleEdit = (data) => {
        setEditMode(true);
        setEditAddr(data);
        setShowAddrModal(true);
    }

    return (
      <>
        {ShippingAddresses && (
          <Card className="my-3">
            <Card.Header
              className="d-flex align-items-baseline"
              style={{ backgroundColor: "#4d5b7e" }}
            >
              <h5 className="text-white">Shipping address</h5>
              <Button
                className="ms-auto d-flex align-items-baseline"
                variant="outline-light"
                onClick={() => {
                  setEditMode(false);
                  setShowAddrModal(true);
                }}
              >
                <span className="material-icons me-3">local_shipping</span>
                <span>Add Shipping Address</span>
              </Button>
            </Card.Header>

            <Card.Body
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr) )", //gridTemplateColumns: 'repeat(5, 1fr)'
                columnGap: "1rem",
                rowGap: "1rem",
              }}
            >
              {ShippingAddresses.map((addr, index) => {
                return (
                  <ShippingAddress
                    key={index}
                    data={addr}
                    onEdit={handleEdit}
                  />
                );
              })}
            </Card.Body>
          </Card>
        )}

        <ShipAddressModal
          edit={editMode}
          shipAddr={editAddr}
          show={showAddrModal}
          onCancel={() => setShowAddrModal(false)}
          onSubmit={handleShipAddrSubmit}
        />
      </>
    );
}

export default ShippingAddressList;