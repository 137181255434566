import React, {useState, useEffect} from 'react';
import {Form} from 'react-bootstrap';
//import { useApi } from "../contexts/ApiContext";

export default function ShipAddrList({onValChange, addresses, selected, ...props}) {

    const [addrId, setAddrId] = useState(-1);
    //const { shipAddresses } = useApi();

    function handleSelectChange(e) {
        setAddrId(e.target.value);
        onValChange(e.target.value);
    }

    return (
      <>
        <Form.Select 
          {...props} 
          onChange={handleSelectChange} 
          value={selected ? selected : addrId}>

          {
            addresses && addresses.length > 0 
            
            ? addresses.map((address, index) => {
                return (
                  <option key={index} value={address.id}>
                    {address.name}, {address.address}
                  </option>
                );
              })
           
            : <option>No address found</option>
          }

        </Form.Select>
      </>
    );
}