import {
    Stack,
    Row,
    Col,
    Button
} from 'react-bootstrap';
import TreeView from './TreeView';
import FileView from './FileView';
import PictureView from './PictureView';
import React, {useState, useContext, useEffect} from 'react';
import { FileContext } from '../contexts/FileContext';



const FileManager = ({dir,view}) => {

    const {getFiles, downloadFile} = useContext(FileContext);
    const [files, setFiles] = useState([]);
    // const [pictures, setPictures] = useState([]);
    const [data, setData] = useState([]);
    const [path, setPath] = useState(dir);

    const handleNodeClick = (node) => {
        setPath(node.path);
        setFiles(node.children);
        //activateNode(data, node);
    }

    const handleClickDownload = (file) => {
        downloadFile(file);
    }

    useEffect( ()=> {
        getFiles(
            dir,
            res => {
                setData(res);
            },
            err => {
                console.log(err)
            }
        )
    }, [])

    return (
      <Stack style={{ height: "Calc(100vh - 120px)", background: "white" }}>
        <Row className="border p-1 m-0">
          <Col md={10}><strong>{path.substr(path.indexOf(dir), path.length)}</strong></Col>
          {/* <Col md={2}><Button size='sm' variant='outline-secondary'>Upload file</Button></Col> */}
        </Row>
        <Row className="border m-0" style={{ height: "Calc(100vh - 158px)"}}>
          <Col md={3} className="p-0 h-100 overflow-auto" style={{borderRight: '1px solid #DEE2E6'}}>
            <TreeView
              data={data}
              types={["directory"]}
              onNodeClick={handleNodeClick}
            />
          </Col>
          <Col md={9} className="p-3 h-100 overflow-auto">
            { (view === 'file') &&
              <FileView data={files} onClickDownload={handleClickDownload} />
            }

            { (view === 'picture') &&
              <PictureView data={files} onClickDownload={handleClickDownload} />
            }
          </Col>
        </Row>
      </Stack>
    );
}

export default FileManager;
