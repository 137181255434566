import './RmaMenu.css';
import {useState, useEffect, useRef} from 'react';

const RmaMenu = ({menuItems, show, position, onClickOutside}) => {
    
    const ref = useRef();
    const [menuWidth, setMenuWidth] = useState(0);


    useEffect( () => {
        const handleClickOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                //console.log('Clicked outside');
                onClickOutside && onClickOutside(e);
            }
        };

        document.addEventListener('click', handleClickOutside, true);
        //console.log('RmaMenu - mount');

        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            //console.log('RmaMenu - unmount');
        }
    }, []);

    if (!show) return null;

    return(
        <div 
            ref= {ref}
            className='menu-container' 
            style={{
                left: position.x - menuWidth, 
                top: position.y, 
                // visibility: show ? 'visible' : 'hidden'
            }}
        >
            <ul
                ref={el => {
                    if (!el) return
                    setMenuWidth(el.getBoundingClientRect().width)
                }}
            >
                {menuItems.map((menuItem, index) => {
                    return menuItem.header ? 
                            (<li className='menu-header' key={index}>{menuItem.title}</li>) : 
                            (<li key={index} onClick={menuItem.onClick}>
                                <span className='material-icons me-2'>{menuItem.icon}</span>
                                <span>{menuItem.title}</span>
                            </li>);
                })}
            </ul>
        </div>
    )
}

export default RmaMenu;