import MenuCardItem from './MenuCardItem';
import Icon from './Icon/';
import {useAuth} from '../contexts/AuthContext';

import RmaLogo from '../images/rma_logo.svg';
import PoLogo from '../images/po_logo.svg';
import DownloadLogo from '../images/download_logo.svg';
import ServiceManualLogo from '../images/service_manual_logo.svg';
import AppNotesLogo from '../images/app_notes_logo.svg';

const menu = [
  {
    id: 0,
    enabled: true,
    title: "RMA",
    link: "/rma",
    image: RmaLogo,
    icon: (
      <Icon.Rma className="ms-2" style={{ width: "50px", height: "50px" }} />
    ),
    roles: ["admin"],
  },
  {
    id: 11,
    enabled: true,
    title: "Brochures",
    link: "/brochures",
    image: RmaLogo,
    icon: (
      <Icon.Brochure className="ms-2" style={{ width: "50px", height: "50px" }} />
    ),
    roles: ["user", "admin"],
  },
  {
    id: 12,
    enabled: true,
    title: "Price Book",
    link: "/pricebook",
    image: RmaLogo,
    icon: (
      <Icon.Pricebook className="ms-2" style={{ width: "50px", height: "50px" }} />
    ),
    roles: ["user", "admin"],
  },
  {
    id: 10,
    enabled: true,
    title: "RMA (Comming soon)",
    link: "/rma",
    image: RmaLogo,
    icon: (
      <Icon.Rma className="ms-2" style={{ width: "50px", height: "50px" }} />
    ),
    roles: ["user"],
  },
  {
    id: 1,
    title: "Purchase Orders",
    link: "/PO",
    image: PoLogo,
    icon: (
      <Icon.Po className="ms-2" style={{ width: "50px", height: "50px" }} />
    ),
    roles: ["admin"],
  },
  {
    id: 2,
    title: "Software Download",
    link: "/software",
    image: DownloadLogo,
    icon: (
      <Icon.Download
        className="ms-2"
        style={{ width: "50px", height: "50px" }}
      />
    ),
    roles: ["user", "admin"],
  },
  {
    id: 3,
    title: "Service Manuals",
    link: "/service_manual",
    image: ServiceManualLogo,
    icon: (
      <Icon.SrvManual
        className="ms-2"
        style={{ width: "50px", height: "50px" }}
      />
    ),
    roles: ["user", "admin"],
  },
  {
    id: 4,
    title: "Application Notes",
    link: "/app_notes",
    image: AppNotesLogo,
    icon: (
      <Icon.AppNotes
        className="ms-2"
        style={{ width: "50px", height: "50px" }}
      />
    ),
    roles: ["user", "admin"],
  },

  {
    id: 5,
    title: "HD Pictures",
    link: "/pictures",
    image: null,
    icon: (
      <Icon.Pictures
        className="ms-2"
        style={{ width: "50px", height: "50px" }}
      />
    ),
    roles: ["user", "admin"],
  },

  {
    id: 6,
    title: "Reports & Charts",
    link: "/reports",
    image: null,
    icon: (
      <Icon.Charts className="ms-2" style={{ width: "50px", height: "50px" }} />
    ),
    roles: ["admin"],
  },

  {
    id: 7,
    title: "Users Management",
    link: "/users",
    image: null,
    icon: (
      <Icon.Users className="ms-2" style={{ width: "50px", height: "50px" }} />
    ),
    roles: ["admin"],
  },
  {
    id: 8,
    title: "Settings",
    link: "/settings",
    image: null,
    icon: (
      <Icon.Settings
        className="ms-2"
        style={{ width: "50px", height: "50px" }}
      />
    ),
    roles: ["admin"],
  },
  {
    id: 9,
    title: "Files",
    link: "/file_man",
    image: null,
    icon: (
      <Icon.Folder className="ms-2" style={{ width: "50px", height: "50px" }} />
    ),
    roles: ["admin"],
  },
];

export default function MenuCard() {

  const { currentUser } = useAuth();

  const newMenu = menu.filter(item => {
    return item.roles.includes( currentUser.role );
  });

  return (
    <div
      id='MenuCard'
      style={{
        display: "grid",
        //gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
        gap: "2rem",
        alignItems: "flex-start",
        width: '100%'
      }}
    >
      {
        
        newMenu.map((item) => (
        <MenuCardItem key={item.id} title={item.title} link={item.link} icon={item.icon}/>
      ))
      }
    </div>
  );
}
