import React, {
  useState,
  useEffect,
  createContext
} from 'react';
import { useAuth } from "./AuthContext";

export const RmaContext = createContext();

const RmaContextProvider = (props) => {
  const { token } = useAuth();

  const [rmas, setRmas] = useState([]);
  const [rmaYears, setRmaYears] = useState([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedYear, setSelectedYear] = useState(2022);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [prodTypes, setProdTypes] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(true);
  const [shippingAddress, setShippingAddress] = useState([]);
  const [loadingShippingAddress, setLoadingShippingAddress] = useState(true);

  async function apiRequest(endPoint, method, data, onSuccess, onFail) {
    try {
      const response = await fetch(endPoint, {
        method: method,
        headers: {
          "Content-type": "application/json",
          Authorization: `kaToken ${token}`,
        },
        body: data && JSON.stringify(data),
      });
      const fetchedData = await response.json(response);
      if (fetchedData.code > 200) onFail(fetchedData);
      else onSuccess(fetchedData.data);
    } catch (error) {
      onFail({
        code: error,
        message: `Error  at endpoint ${endPoint}`,
      });
    }
  }

  /** RMA Functions *********************************************************************/
  const getRmaYears = () => {
    apiRequest(
      "/api/rma/years",
      "GET",
      null,
      (data) => {
        let years;

        if (data.length > 0) {
          years = data.map((obj) => {
            return obj.year;
          });
          setRmaYears(years);
        } else {
          setRmaYears([2022]);
        }

        setSelectedYear(years[0]);
        //onSuccess(data);
      },
      (error) => {
        console.log("Error getting RMA years ", error);
        //onFail(error);
      }
    );
  };

  const getRmaAll = (year, onSuccess, onFail) => {
    apiRequest(`/api/rma?year=${year}`, "GET", null, onSuccess, onFail);
  };

  const addRma = (rma, onSuccess, onFail) => {
    apiRequest(
      "/api/rma",
      "POST",
      rma,
      (data) => {
        setRmas([data, ...rmas]);
        onSuccess(data);
      },
      (error) => onFail(error)
    );
  };

  const editRma = (updatedRma, onSuccess, onFail) => {
    apiRequest(
      `/api/rma/${updatedRma.id}`,
      "PUT",
      updatedRma,
      (data) => {
        setRmas(
          rmas.map((rma) => (rma.id === updatedRma.id ? updatedRma : rma))
        );
        onSuccess(data);
      },
      (error) => onFail(error)
    );
  };

  const patchRma = (rma, newFieldData, onSuccess, onFail) => {
    apiRequest(
      `/api/rma/${rma.id}`,
      "PATCH",
      newFieldData,
      (data) => {
        setRmas(
          rmas.map((lrma) =>
            lrma.id === rma.id ? { ...lrma, ...newFieldData } : lrma
          )
        );
        onSuccess(data);
      },
      (error) => onFail(error)
    );
  };

  const delRma = (rmaId, onSuccess, onFail) => {
    apiRequest(
      `/api/rma/${rmaId}`,
      "DELETE",
      null,
      () => {
        setRmas(rmas.filter((rma) => rma.id !== rmaId));
        onSuccess();
      },
      (error) => {
        console.log("Delete error");
        onFail(error);
      }
    );
  };

  /** Product functions **************************************************/

  const getProductAll = (onSuccess, onFail) => {
    apiRequest("/api/products", "GET", null, onSuccess, onFail);
  };

  const addProduct = (product, onSuccess, onFail) => {
    apiRequest("/api/products", "POST", product, onSuccess, onFail);
  };

  const updateProduct = (product, onSuccess, onFail) => {
    apiRequest("/api/products", "PUT", product, onSuccess, onFail);
  };

  const deleteProduct = (product, onSuccess, onFail) => {
    apiRequest("/api/products", "DELETE", product, onSuccess, onFail);
  };

  /** SHIPPING ADDRESS Functions ***************************************/

  const getShipAddressAll = (onSuccess, onFail) => {
    apiRequest(`/api/ship-addr`, "GET", null, onSuccess, onFail);
  };

  /** RMA Product functions */

  const setProductWarranty = (id, rmaId, warranty, onSuccess, onFail) => {
    apiRequest(
      `/api/rma_products/${id}`,
      "PATCH",
      { warranty: warranty },
      (data) => {
        const newRmas = rmas.map((lrma) =>
          lrma.id === rmaId
            ? {
                ...lrma,
                products: lrma.products.map((product) =>
                  product.id === id
                    ? { ...product, warranty: warranty }
                    : product
                ),
              }
            : lrma
        );
        setRmas(newRmas);
        onSuccess(data);
      },
      onFail
    );
  };

  const setProductRepaired = (id, rmaId, repaired, cost, onSuccess, onFail) => {
    apiRequest(
      `/api/rma_products/${id}`,
      "PATCH",
      { repaired: repaired, rep_price: cost },
      (data) => {
        const newRmas = rmas.map((lrma) =>
          lrma.id === rmaId
            ? {
                ...lrma,
                products: lrma.products.map((product) =>
                  product.id === id
                    ? { ...product, repaired: repaired, rep_price: cost }
                    : product
                ),
              }
            : lrma
        );
        setRmas(newRmas);
        onSuccess(data);
      },
      onFail
    );
  };

  const setProductReplaced = (
    id,
    rmaId,
    replaced,
    replaced_sn,
    onSuccess,
    onFail
  ) => {
    apiRequest(
      `/api/rma_products/${id}`,
      "PATCH",
      { replaced: replaced, replaced_sn: replaced_sn },
      (data) => {
        const newRmas = rmas.map((lrma) =>
          lrma.id === rmaId
            ? {
                ...lrma,
                products: lrma.products.map((product) =>
                  product.id === id
                    ? {
                        ...product,
                        replaced: replaced,
                        replaced_sn: replaced_sn,
                      }
                    : product
                ),
              }
            : lrma
        );
        setRmas(newRmas);
        onSuccess(data);
      },
      onFail
    );
  };

  const setProductNotes = (id, rmaId, notes, onSuccess, onFail) => {
    apiRequest(
      `/api/rma_products/${id}`,
      "PATCH",
      { notes: notes },
      (data) => {
        const newRmas = rmas.map((lrma) =>
          lrma.id === rmaId
            ? {
                ...lrma,
                products: lrma.products.map((product) =>
                  product.id === id ? { ...product, notes: notes } : product
                ),
              }
            : lrma
        );
        setRmas(newRmas);
        onSuccess(data);
      },
      (error) => {
        onFail(error.message);
      }
    );
  };

  /**  Reports */

  const getReturnedByModel = (year, month, onSuccess, onFail) => {
    const cond = month > 0 && month < 13 ? `&month=${month}` : "";
    apiRequest(
      `/api/report/product_by_model?year=${year}${cond}`,
      "GET",
      null,
      onSuccess,
      onFail
    );
  };

  const getReturnedByCategory = (year, month, onSuccess, onFail) => {
    const cond = month > 0 && month < 13 ? `&month=${month}` : "";
    apiRequest(
      `/api/report/product_by_category?year=${year}${cond}`,
      "GET",
      null,
      onSuccess,
      onFail
    );
  };

  /** File manager */

  // const getFiles = (dir, onSuccess, onFail) => {
  //   apiRequest(`/api/files?path=${dir}`, 'GET', null, onSuccess, onFail);
  // }


  const value = {
    rmas,
    rmaYears,
    currentPage,
    setCurrentPage,
    search,
    setSearch,
    selectedYear,
    setSelectedYear,
    addRma,
    editRma,
    patchRma,
    delRma,
    loading,
    products,
    setProducts,
    addProduct,
    updateProduct,
    deleteProduct,
    prodTypes,
    setProductWarranty,
    setProductRepaired,
    setProductReplaced,
    setProductNotes,
    loadingProducts,
    shippingAddress,
    loadingShippingAddress,
    getReturnedByModel,
    getReturnedByCategory,
  };

  useEffect(() => {
    getRmaYears();
  }, []);

  useEffect(() => {
    //console.log('RmaContext Mount - selectedYear changed');

    setLoading(true);

    getRmaAll(
      selectedYear,
      (data) => {
        setRmas(data);
        setLoading(false);
      },
      (error) => {
        console.log("getRmaAll error: ", error);
        setRmas(null);
        setLoading(false);
      }
    );

    return () => {
      //console.log('RmaContext Unmount')
    }

  }, [selectedYear]);  //[selectedYear]);

  useEffect(() => {
    setLoadingProducts(true);

    getProductAll(
      (data) => {
        setProducts(data);
        const categories = data
          .map((radio) => radio.category)
          .filter((cat, i, ar) => ar.indexOf(cat) === i);
        setProdTypes(categories);
        setLoadingProducts(false);
      },
      (error) => {
        console.log("getProductAll error: ", error);
        setLoadingProducts(false);
      }
    );
  }, []);

  useEffect(() => {
    setLoadingShippingAddress(true);

    getShipAddressAll(
      (data) => {
        setShippingAddress(data);
        setLoadingShippingAddress(false);
      },
      (error) => {
        console.log("getShippingAddressAll error: ", error);
        setLoadingShippingAddress(false);
      }
    );
  }, []);

  return (
    <RmaContext.Provider value={value}>
      {
        // loading ? 'Loading...' : props.children
        props.children
      }
    </RmaContext.Provider>
  );
}

export default RmaContextProvider;



/** THIS IS A BACKUP */

// import React, {
//     createContext,
//     useContext,
//     useReducer,
//     useEffect,
//     useState
// } from 'react';
// import RmaReducer from './RmaReducer';
// import { useAuth } from "./AuthContext";

// //Initial state

// const initialState = {
//     rmas: [],
//     products: [],
//     shippingAddresses: [],
//     loading: true
// }

// //Create context

// export const RmaContext = createContext(initialState);

// export function useRmaContext() {
//   return useContext(RmaContext);
// }

// //Provider Component

// export const RmaProvider = ( { children } ) => {
//   //const [state, dispatch] = useReducer(RmaReducer, initialState);
//   const [state, setState] = useState(initialState);
//   const { token } = useAuth();

//   //API functions

//   const apiRequest = (endPoint, method, data, onSuccess, onFail) => {
//     fetch(endPoint, {
//       method: method,
//       headers: {
//         "Content-type": "application/json",
//         Authorization: `kaToken ${token}`,
//       },
//       body: data && JSON.stringify(data),
//     })
//       .then((res) => {
//         if (res.ok) return res.json();
//         onFail({
//           code: res.status,
//           message: `Error ${res.status} at endpoint ${endPoint}`,
//         });
//       })
//       .then((data) => {
//         if (data.code && data.code === 200) onSuccess(data.data);
//         else onFail(`Error at endpoint ${endPoint}`);
//       });
//   };

//   const getRmaAll = (onSuccess, onFail) => {
//     apiRequest("/api/rma", "GET", null, onSuccess, onFail);
//   };

//   //Actions

// //   const setRmas = (rmas) => {
// //       dispatch({
// //           type: "SET_RMAS",
// //           payload: rmas
// //       })
// //   }

// //   const addRma = (rma) => {
// //     //call to API to add rma, if response ok then dispatch...
// //     dispatch({
// //       type: "ADD_RMA",
// //       payload: rma,
// //     });
// //   };

//   useEffect( () => {
//     setState({...state, loading: true});

//     getRmaAll(
//         data => {
//             //setRmas(data);
//             //state.rmas = [...data];
//             setState({...state, loading: false, rmas: [...data]} );
//             console.log('RmaContext, loading:', state.loading, state.rmas)
//         },
//         error => {
//             alert(error.message);
//             setState({ ...state, loading: false });
//         }
//     );
//   }, [] )

//   return (
//     <RmaContext.Provider
//       value={{
//         loading: state.loading,
//         rmas: state.rmas,
//         // setRmas,
//         // addRma,
//       }}
//     >
//       {state.loading ? "Loading..." : children}
//     </RmaContext.Provider>
//   );
// }