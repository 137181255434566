import React, {useState} from 'react';

const TreeNode = ({node, types, colapsed, onClick}) => {
    const [col, setCol] = useState(colapsed);
    const [isHover, setIsHover] = useState(false);
    const [isActive, setIsActive] = useState(node.active);

    const liStyle = {
        whiteSpace: 'nowrap',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '16px',
        fontWeight: 300,
    }

    const spanStyle = {
      backgroundColor: isHover || isActive ? "#113355" : "transparent",
      color: isHover || isActive ? "#FFFFFF" : "#212529",
      padding: "3px",
      borderRadius: "5px",
      cursor: 'pointer',
      fontSize: '14px'
    };

    const handleColapseClick = () => {
        setCol(!col);
    }

    const handleMouseEnter = () => { setIsHover(true) }

    const handleMouseLeave = () => { setIsHover(false) };

    const handleClick = (e) => { 
        // alert(e.target.innerHTML);
        // alert(node.nodes);
        //setIsActive(true);
        onClick(node);
    }

    if (types.includes(node.type)) {
        return (
          <li style={liStyle} >
            
            {
                node.children.length > 0 
                ? col 
                    ? (<span className="material-icons-outlined md-16" style={{cursor: 'pointer', fontSize:'1rem'}} onClick={handleColapseClick}>chevron_right</span>)
                    : (<span className="material-icons-outlined md-16" style={{cursor: 'pointer', fontSize:'1rem'}} onClick={handleColapseClick}>expand_more</span>)
                : (<span>&nbsp;</span>)
            }

            {
                node.type === "directory" 
                ? (<span className="material-icons md-16 text-warning" style={{fontSize:'1.2rem'}}>&#xe2c7;</span>) 
                : (<span className="material-icons-outlined md-16 text-primary" style={{fontSize:'1.2rem'}}>&#xe873;</span>)
            }

            <span
                style={spanStyle}
                onMouseEnter={handleMouseEnter} 
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
            >
                {node.name}
            </span>
            
            <ul style={{ listStyle: "none", display: col ? 'none' : 'block' }}>
              {node.children.map((node, i) => {
                return <TreeNode key={i} node={node} types={types} colapsed={false} onClick={onClick}/>;
              })}
            </ul>
          </li>
        );
    } else {
        return null;
    }
}

export default TreeNode;