import { Modal, Form, Button, Stack } from 'react-bootstrap';
import { useRef, useState } from 'react';

const PasswordModal = ({show, id, onSubmit, onCancel}) => {

    const passwordRef = useRef();
    const passwordConfirmationRef = useRef();
    const [error, setError] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setError(false);

        if (passwordRef.current.value === passwordConfirmationRef.current.value) {
            onSubmit(id, passwordRef.current.value)
        } else {
            setError(true);
        }

        
    } 

    const handleCancelClick = () => {
        setError(false);
        onCancel();
    }


    return(
        <Modal show={show} onHide={onCancel}>
            <Modal.Header
                closeButton
                className="text-white"
                style={{ backgroundColor: "#4D5B7E" }}
            >
                <Modal.Title>Set new password</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                        type="password"
                        required
                        ref={passwordRef}
                        autoComplete="new-password"
                        maxLength={30}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="passwordConfirmation">
                        <Form.Label>Password Confirmation</Form.Label>
                        <Form.Control
                        type="password"
                        required
                        ref={passwordConfirmationRef}
                        autoComplete="new-password"
                        maxLength={30}
                        />
                    </Form.Group>

                    {error && (<span className="text-danger">Error: Passwords must match !</span>)}

                    <Stack direction='horizontal'>
                        <Button className='ms-auto' variant='secondary' type='reset' onClick={handleCancelClick}>Cancel</Button>
                        <Button className='ms-3' variant='primary' type='submit'>OK</Button>
                    </Stack>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default PasswordModal;