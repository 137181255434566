import {Form} from 'react-bootstrap';
//import { useApi } from '../contexts/ApiContext';
import { useState } from 'react';

export default function ProductList({ onChange, products, selected, ...props}) {

    //const { products } = useApi();
    const [productName, setProductName] = useState( products && (products.length > 0) ? products[0].name : undefined);
    const [categories, setCategories] = useState( products && products.map(p=>p.category).filter( (c,i,ar)=>ar.indexOf(c)===i));

    function handleSelectChange(e) {
        setProductName(e.target.value);
        onChange(e);
    }

    let categorizedProducts = [];

    let pid = products[products.length-1].id;
    for (let i=0; i<categories.length; i++) {
        pid++;
        categorizedProducts.push({id: pid, name: categories[i], category:'category'});
        for (let j=0; j<products.length; j++) {
            if (products[j].category === categories[i]) categorizedProducts.push(products[j])
        }
    }

    return (
        <Form.Select 
            {...props}  
            onChange={handleSelectChange} 
            value={selected ? selected : productName}
        >
            {
                categorizedProducts && (categorizedProducts.length > 0) 
                
                ? categorizedProducts.map( (product, index) => {

                    if (product.category === 'category') {

                    return (
                         <option key={index} disabled>--- {product.name} ---</option> 
                    )
                    } else {
                        return (
                            <option key={index} value={product.name}>{product.name}</option>
                        )
                    }
                  } ) 

                : <option>No product found</option>
            }
        </Form.Select>

    )
}