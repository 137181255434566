import Page from "../components/Page";
import FileManager from "../components/FileManager";
import FileContextProvider from "../contexts/FileContext";

const FileManagerPage = () => {

  return (
    <Page title="File Manager">
      <FileContextProvider>
        <FileManager dir={''} view='file'/>
      </FileContextProvider>
    </Page>
  );
};

export default FileManagerPage;
