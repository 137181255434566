import Page from "../components/Page";
import RmaContextProvider from "../contexts/RmaContext";
import Reports from '../components/Reports';

const ReportsPage = () => {

  return (
    <Page title="Reports & Charts">
      <RmaContextProvider>
        <Reports/>
      </RmaContextProvider>
    </Page>
  );
};

export default ReportsPage;
