import {
    Table,
    Button,
    Stack,
    Modal,
    Spinner
} from 'react-bootstrap';
import React, {useContext, useState} from 'react';
import { RmaContext } from '../contexts/RmaContext';
import ProductModal from './ProductModal';
import ConfirmationDialog from './ConfirmationDialog';

const ProductsAndCategories = ( props ) => {

    const {data, ...rest} = props;
    const {products, setProducts, addProduct, updateProduct, deleteProduct, loadingProducts} = useContext(RmaContext);
    const [showProductModal, setShowProductModal] = useState(false);
    const [productModalEdit, setProductModalEdit] = useState(false);
    const [editProduct, setEditProduct] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const newProduct = (product) => {
      setSubmitting(true);
      addProduct(
        product,
        data => {
          setProducts([...products, product]);
          setSubmitting(false);
        },
        err => {
          console.log(err.message);
          setSubmitting(false);
        }
      )
    }

    const changeProduct = (product) => {
      setUpdating(true)
      updateProduct(
        product,
        (data) => {
          for (let i = 0; i < products.length; i++) {
            if (products[i].id === product.id) {
              products[i] = {...products[i], name: product.name, category: product.category};
              break;
            }
          }
          setUpdating(false);
        },
        (err) => {
          console.log(err.message);
          setUpdating(false);
        }
      );
    }

    const handleDelete = (product) => {
      setShowConfirmDelete(false);
      setDeleting(true);
      deleteProduct(
        product,
        (data) => {
          const newProducts = products.filter( prod => prod.id !== product.id);
          setProducts(newProducts);
          setDeleting(false);
        },
        (err) => {
          console.log(err.message);
          setDeleting(false);
        }
      );
    }

    return (
      <div {...rest}>
        <div
          className="overflow-auto mb-3"
          style={{ height: "Calc(100vh - 300px)" }}
        >
          <Table bordered hover responsive size="sm">
            <thead>
              <tr>
                <th style={{ width: "2em", textAlign: "center" }}>#</th>
                <th>Product name</th>
                <th>SKU</th>
                <th>Category</th>
                <th>Picture</th>
                <th style={{ width: "3em", textAlign: "center" }}>Actions</th>
              </tr>
            </thead>

            <tbody>
              {loadingProducts ? (
                <tr>
                  <td colSpan="6">Loading...</td>
                </tr>
              ) : (
                products.map((p, i) => {
                  return (
                    <tr key={i}>
                      <td style={{ textAlign: "right" }}>{i + 1}</td>
                      <td>{p.name}</td>
                      <td>sku code</td>
                      <td>{p.category}</td>
                      <td>picture</td>
                      <td style={{ textAlign: "center" }}>
                        {updating && editProduct.id === p.id ? (
                          <Spinner
                            className="me-2"
                            animation="border"
                            as="span"
                            size="sm"
                            variant="warning"
                          />
                        ) : (
                          <span
                            className="material-icons"
                            onClick={() => {
                              if (!submitting && !deleting && !updating) {
                                setEditProduct(p);
                                setProductModalEdit(true);
                                setShowProductModal(true);
                              }
                            }}
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "orange",
                            }}
                          >
                            edit
                          </span>
                        )}

                        {deleting && editProduct.id === p.id ? (
                          <Spinner
                            className="me-2"
                            animation="border"
                            as="span"
                            size="sm"
                            variant="danger"
                          />
                        ) : (
                          <span
                            className="material-icons"
                            onClick={() => {
                              setEditProduct(p);
                              setShowConfirmDelete(true);
                            }}
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "red",
                            }}
                          >
                            delete
                          </span>
                        )}
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </Table>
        </div>

        <Stack direction="horizontal">
          <Button
            disabled={submitting || deleting || updating}
            className="ms-auto"
            onClick={() => {
              setProductModalEdit(false);
              setShowProductModal(true);
            }}
          >
            {submitting && (
              <Spinner
                className="me-2"
                animation="border"
                as="span"
                size="sm"
              />
            )}
            Add Product
          </Button>
        </Stack>

        <ProductModal
          show={showProductModal}
          edit={productModalEdit}
          data={editProduct}
          onClose={() => setShowProductModal(false)}
          onEnter={(product) => {
            setShowProductModal(false);
            newProduct(product);
          }}
          onEdit={(product) => {
            setShowProductModal(false);
            changeProduct(product);
          }}
        />

        <ConfirmationDialog
          text={`Do you want to delete ${editProduct.name}`}
          data={editProduct}
          show={showConfirmDelete}
          onHide={() => setShowConfirmDelete(false)}
          onYes={handleDelete}
        />
      </div>
    );
}

export default ProductsAndCategories;