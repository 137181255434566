import {
    Table
} from 'react-bootstrap';
import React, {useContext} from 'react';
import { FileContext } from "../contexts/FileContext";

const FileView = (props) => {
    const {data, onClickDownload, ...rest} = props;

    const { formatSize } = useContext(FileContext);

    const tableStyle = {
      fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
      fontWeight: 300,
    };

    return (
      <div {...rest}>
        <Table style={tableStyle} bordered hover size='sm'>
          <thead>
            <tr>
              <th>Filename</th>
              <th style={{ textAlign: "right" }}>Date</th>
              <th style={{ textAlign: "right" }}>Size</th>
              <th style={{ width: "5em", textAlign: "center" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map((file, i) => {
              if (file.type === "file") {
                return (
                  <tr key={i}>
                    <td>{file.name}</td>
                    <td style={{ textAlign: "right" }}>{new Date(file.mtime).toLocaleDateString()}</td>
                    <td style={{textAlign:'right'}}>{formatSize(file.size)}</td>
                    <td style={{ width: "5em", textAlign: "center" }}>
                      <span
                        className="material-icons"
                        style={{
                          color: "blue",
                          cursor: "pointer",
                        }}
                        onClick={() => onClickDownload(file)}
                      >
                        file_download
                      </span>
                    </td>
                  </tr>
                );
              } else return null;
            })}
          </tbody>
        </Table>
      </div>
    );
}

export default FileView;
