// import DownloadPage from "../components/DownloadPage";
//
// const SoftwarePage = () => {
//
//   return (
//     <DownloadPage title='Software Download' queryUrl='/api/files/software'/>
//   );
// };

import FileContextProvider from "../contexts/FileContext";
import Page from "../components/Page";
import FileManager from "../components/FileManager";

const SoftwarePage = () => {

  return (
    <Page title="Software">
      <FileContextProvider>
        <FileManager dir={"Software"} view='file'/>
      </FileContextProvider>
    </Page>
  )

};

export default SoftwarePage;
