import { 
    Form, 
    Modal, 
    Button, 
    Row, 
    Col, 
    Stack 
} from "react-bootstrap";
import React, {useRef} from 'react';

const ProductModal = ({show, edit, data, onClose, onEnter, onEdit}) => {

    const nameRef = useRef();
    const categoryRef = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (edit) {
            const updatedProduct = {
              id: data.id,  
              name: nameRef.current.value,
              category: categoryRef.current.value,
            };
            onEdit(updatedProduct);
        } else {
            const newProduct = {
              name: nameRef.current.value,
              category: categoryRef.current.value,
            };
            onEnter(newProduct);
        }
    }

  return (
    <Modal show={show} onHide={onClose}>
      <Form onSubmit={handleSubmit}>
        <Modal.Header
          className="text-white"
          closeButton
          style={{ backgroundColor: "#4D5B7E" }}
        >
          {edit ? (
            <Modal.Title>Edit Product</Modal.Title>
          ) : (
            <Modal.Title>Add new product</Modal.Title>
          )}
        </Modal.Header>

        <Modal.Body>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
              ref={nameRef}
              type="text"
              maxLength={30}
              defaultValue={edit ? data.name : ""}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="category">
            <Form.Label>Category</Form.Label>
            <Form.Control
              ref={categoryRef}
              type="text"
              maxLength={30}
              defaultValue={edit ? data.category : ""}
            />
          </Form.Group>

          <Stack direction="horizontal">
            <Button className="ms-auto me-3" variant="danger" onClick={onClose}>
              Cancel
            </Button>

            <Button variant="success" type="submit">
                { edit ? 'Update' : 'Add'}
            </Button>
          </Stack>
        </Modal.Body>
      </Form>
    </Modal>
  );
};

export default ProductModal;
