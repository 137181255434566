import { Modal, Form, Button } from "react-bootstrap";
import React, { useRef } from "react";

const RepairCostModal = ({ show, cost, onSubmit, onHide }) => {
  const costRef = useRef();

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{"Repairing cost:"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="cost">
            <Form.Label>Cost (usd):</Form.Label>
            <Form.Control
              as="input"
              name="cost"
              defaultValue={cost}
              required
              ref={costRef}
              minLength={15}
              maxLength={15}
              type="number"
            />
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button className="me-3" variant="secondary" onClick={onHide}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </div>
        </Modal.Body>
      </Form>
    </Modal>
  );
};

export default RepairCostModal;
