import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';

export default function NotFoundPage() {
  return (
    <Container
      fluid
      className="d-flex align-items-center justify-content-center"
      style={{
        minHeight: "100vh",
        backgroundImage:
          "linear-gradient(135deg, rgba(51,85,119,0.4), rgba(51,85,119,1))",
      }}
    >
      <div className="w-100 d-flex flex-column" style={{ maxWidth: "500px" }}>
        <span className='fs-1 fw-bold text-warning shadow p-4'>404 Page not found !</span>
        <Link to='/' className='mt-5 btn btn-secondary'>Go Back</Link>
      </div>
    </Container>
  );
}
