import React, { useContext, useState, useEffect } from 'react'

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function AuthProvider({ children }) {

    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState();
    const allowedRoles = ['admin'];
    const [isAllowedRole, setIsAllowedRole] = useState();
    

    async function logIn (email, password) {
        let success = false;

        const res = await fetch('/api/login', 
                    {
                        method: 'POST',
                        headers: {
                            'Content-type': 'application/json'
                        },
                        body: JSON.stringify({email: email, password: password})
                    });
        const data = await res.json();

        if (data.user) {

            const user = JSON.stringify(data.user);
            const resToken = data.token;

            localStorage.setItem('currentUser', user);
            localStorage.setItem('token', resToken);

            setCurrentUser( JSON.parse(user) );
            setToken( resToken );
            setIsAllowedRole(allowedRoles.includes(data.user.role));
            
            success = true;
        } else {
            throw new Error("Cant login");
        }

        return success;
    }

    function logOut() {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('token');
        setCurrentUser();
        setToken();
    }

    async function signUp(data) {
        const res = await fetch("/api/register", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(data),
        });
        const jsonResponse = await res.json();

        return jsonResponse;
    }

    async function getUserInfo(userId, onSuccess, onFail) {
      
      try {
        const response = await fetch(`/api/users/${userId}`, {
          method: "GET",
          headers: {
            "Content-type": "application/json",
            Authorization: `kaToken ${token}`,
          },
        });
        const fetchedData = await response.json(response);
        if (fetchedData.code > 200) onFail(fetchedData) 
        else onSuccess(fetchedData.data);
      } catch (error) {
          onFail({
            code: error,
            message: `Error  at endpoint /api/users/${userId}`
          });
      }
      
    }

    useEffect(() => {
      checkTokenExpired();

      const user = JSON.parse(localStorage.getItem("currentUser"));
      if (user) {
        setCurrentUser(user);
        setIsAllowedRole(allowedRoles.includes(user.role));
      }

      const storedToken = localStorage.getItem("token");
      if (storedToken) {
        setToken(storedToken);
      }

      setInterval(checkTokenExpired, 3600000);

      setLoading(false);
    }, []);

    

    async function checkTokenExpired() {

        const storedToken = localStorage.getItem('token');

        if (storedToken) {

            const res = await fetch("/api/user/check_expire", {
              method: "GET",
              headers: {
                "Content-type": "application/json",
                Authorization: `kaToken ${storedToken}`,
              },
            })

            if (res.status === 200) {
                const jsonresponse = await res.json();
                const newToken = jsonresponse.token;
                localStorage.setItem("token", newToken);
                setToken(newToken);
            } else {
                logOut();
            }

        }

    }



    const value = {
      currentUser,
      token,
      isAllowedRole,
      logIn,
      logOut,
      signUp,
      getUserInfo
    };
    
    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
