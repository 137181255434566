import Page from '../components/Page';
import UserList from '../components/UserList';
import {useState, useEffect} from 'react';
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from 'react-router-dom';
import { Alert } from 'react-bootstrap';


const UsersPage = () => {

    const [users, setUsers] = useState([]);
    const { token } = useAuth();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    function getUsers() {
        setLoading(true);

        fetch( '/api/users', {
            method: "GET",
            headers: {
              Authorization: `kaToken ${token}`,
            },
          })
            .then((res) => {
              if (res.ok) {
                
                return res.json();
              }
              history.push('/login');
              throw res;
            })
            .then((data) => {
                if (data.code && data.code !== 200) {history.push('/login')} else {setUsers(data)}
                setLoading(false);
            });
    }

    useEffect(()=>{
        getUsers();
    }, []);

    return (
        <Page title='Users Management'>
          {
            loading
              ? <Alert variant='info' className='m-3 shadow'>Loading data...</Alert>
              : <UserList title='User List' users={users} />
          }
        </Page>
    )
}

export default UsersPage;