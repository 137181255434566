import LoginPage from "./pages/LoginPage";
import SignUpPage from './pages/SignUpPage';
import { AuthProvider } from "./contexts/AuthContext";
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import HomePage from "./pages/HomePage";
import RmaHomePage from "./pages/RmaHomePage";
import NotFoundPage from "./pages/NotFoundPage";
import ProfilePage from './pages/ProfilePage';
import ServiceManualPage from "./pages/ServiceManualPage";
import UsersPage from './pages/UsersPage';
import AppNotesPage from "./pages/AppNotesPage";
import SoftwarePage from './pages/SoftwarePage';
import ReportsPage from "./pages/ReportsPage";
import PrivateRoute from "./components/PrivateRoute";
import PicturesPage from "./pages/PicturesPage";
import SettingsPage from "./pages/SettingsPage";
import FileManagerPage from "./pages/FileManagerPage";
import BrochuresPage from "./pages/BrochuresPage";
import PricebookPage from "./pages/PricebookPage";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Switch>
          <PrivateRoute exact path="/" component={HomePage} />
          <PrivateRoute exact path="/rma" component={RmaHomePage} />
          <PrivateRoute path="/brochures" component={BrochuresPage} />
          <PrivateRoute path="/pricebook" component={PricebookPage} />
          <PrivateRoute path="/service_manual" component={ServiceManualPage} />
          <PrivateRoute path="/software" component={SoftwarePage} />
          <PrivateRoute path="/app_notes" component={AppNotesPage} />
          <PrivateRoute path="/users" component={UsersPage} />
          <PrivateRoute path="/profile" component={ProfilePage} />
          <PrivateRoute path="/reports" component={ReportsPage} />
          <PrivateRoute path="/pictures" component={PicturesPage} />
          <PrivateRoute path="/settings" component={SettingsPage} />
          <PrivateRoute path="/file_man" component={FileManagerPage} />
          <Route path="/signup" component={SignUpPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="*" component={NotFoundPage} />
        </Switch>
      </AuthProvider>
    </Router>
  );
}

export default App;
