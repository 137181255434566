import Page from "../components/Page";
import Brochures from '../components/Brochures';
import FileContextProvider from "../contexts/FileContext";


const BrochuresPage = () => {

    return (
      <Page title="Product Brochures">
        <FileContextProvider>
            <Brochures/>
        </FileContextProvider>
      </Page>
    )
  
  };
  
  export default BrochuresPage;