import { Modal, Form, Button } from 'react-bootstrap';

const ConfirmationDialog = ({text, data, show, onHide, onYes}) => {
    return (
      <Modal show={show} onHide={onHide}>
          <Modal.Header>Please confirm</Modal.Header>

          <Modal.Body>
            <div className="px-3 me-3">{text}</div>

            <div className="d-flex justify-content-end">
              <Button className="me-3" variant="secondary" onClick={onHide}>
                NO
              </Button>
              <Button variant="primary" onClick={()=>onYes(data)}>
                YES
              </Button>
            </div>
          </Modal.Body>
      </Modal>
    );
}

export default ConfirmationDialog;