import { Form, Modal, Button, Row, Col, Stack, Accordion, InputGroup, Toast } from "react-bootstrap"
import { useRef } from 'react';

const DateModal = ({title, show, onSubmit, onHide}) => {

    const dateRef = useRef();

    
    return (
        <Modal
            show={show}
            onHide={onHide}
        >
            <Form onSubmit={onSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form.Group className="mb-3" controlId="date">
                        <Form.Label for="date">Date</Form.Label>
                        <Form.Control
                            // id="date-modal-date"
                            name="date"
                            ref={dateRef}
                            defaultValue={new Date().toISOString().substring(0, 10)} // "2022-01-27"
                            type="date"
                            required
                        />
                    </Form.Group>

                    <div className="d-flex justify-content-end">
                        <Button className='me-3' variant="secondary" onClick={onHide}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Set Date
                        </Button>
                    </div>
                </Modal.Body>
            </Form>

            
        </Modal>
    )
}

export default DateModal;