import Page from '../components/Page';
import {
    Card,
    Tabs,
    Tab
} from 'react-bootstrap';
import RmaContextProvider from "../contexts/RmaContext";
import ProductsAndCategories from '../components/ProductsAndCategories';
import AdminSettings from '../components/AdminSettings';


const SettingsPage = () => {
    return (
      <Page title="Settings">
        <RmaContextProvider>
          <Card
            className="shadow"
            style={{height: "Calc(100vh - 120px)" }}
          >
            <Card.Body>
              <Tabs defaultActiveKey="products">
                <Tab
                  className="border-start border-end border-bottom p-3"
                  eventKey="products"
                  title="Products"
                  style={{ height: "Calc(100vh - 200px)" }}
                >
                  <ProductsAndCategories
                    className="p-3"
                    style={{ height: "Calc(100vh - 230px)"}}
                  />
                </Tab>

                <Tab
                  className="p-3 border-start border-end border-bottom"
                  eventKey="admin"
                  title="Admin settings"
                  style={{ height: "Calc(100vh - 200px)" }}
                >
                  <AdminSettings/>
                  
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </RmaContextProvider>
      </Page>
    );
}

export default SettingsPage;