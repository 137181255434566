import Chart from "./Chart";

class PieChart extends Chart{
    constructor(canvas, width, height, title, data) {
        super(canvas, width, height, title, data);
        this.total = this.data.reduce( (acumulator, curr) => acumulator + curr.value, 0);
        this.radio = 0.3 * ( (this.CANVAS_WIDTH > this.CANVAS_HEIGHT) ? this.CANVAS_HEIGHT : this.CANVAS_WIDTH );
        this.x = this.CANVAS_WIDTH * 0.25//this.radio + 10;
        this.y = this.CANVAS_HEIGHT * 0.55;
    }
    draw() {
        super.draw();
        
        if (this.data) {
            let start = 0;
        let stop = 0;
        this.ctx.font = `${12}px Arial`;
        this.ctx.textAlign = "left";
        
        for (let i=0; i<this.data.length; i++) {
            stop = start + (2 * Math.PI * this.data[i].value/this.total);
            this.ctx.beginPath();
            this.ctx.strokeStyle = "white";
            this.ctx.lineWidth = 3;
            this.ctx.fillStyle = this.data[i].color;
            this.ctx.moveTo(this.x, this.y);
            this.ctx.arc(this.x, this.y, this.radio, start, stop);
            this.ctx.fill();
            this.ctx.fillRect(this.CANVAS_WIDTH/2 + 30, this.CANVAS_HEIGHT/16+15 + Math.floor(this.CANVAS_HEIGHT/16) + 20*i, 30, 10);
            this.ctx.fillStyle = "black";
            this.ctx.fillText(this.data[i].label + ' | ' + this.data[i].value + ' | ' + Math.round((this.data[i].value)/this.total*100) + ' %', this.CANVAS_WIDTH/2 + 70, this.CANVAS_HEIGHT/16+15 + Math.floor(this.CANVAS_HEIGHT/16) + 12 + 20*i);
            this.ctx.stroke();
            start = stop;
            this.ctx.closePath();
        }
        }
        
        
    }
}

export default PieChart;