import User from "./User";
import { Card, Table, Button, Toast, ToastContainer, Form } from "react-bootstrap";
import ConfirmationDialog from "./ConfirmationDialog";
import { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import UserModal from "./UserModal";
import PasswordModal from "./PasswordModal";

const UserList = ({ title, users }) => {

  const [localUsers, setLocalUsers] = useState(users);
  const [deleteId, setDeleteId] = useState(-1);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [confirmDlgText, setConfirmDlgText] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("Hello world");
  const [toastBg, setToastBg] = useState("info");
  const { token } = useAuth();
  const [showUserModal, setShowUserModal] = useState(false);
  const [userModalTitle, setUserModalTitle] = useState('');
  const [userModalEdit, setUserModalEdit] = useState(false);
  const [userModalData, setUserModalData] = useState({});
  const { signUp } = useAuth();
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [passwordId, setPasswordId] = useState(-1);
  const [search, setSearch] = useState("");
  const [sortedField, setSortedField] = useState('company');

  
  //console.log(currentUsers);    

  function handleSearch(e) {
    setSearch(e.target.value.toUpperCase());
  }

  function showMessage(bg, message) {
    setToastBg(bg);
    setToastMessage(message);
    setShowToast(true);
  }

  const handleAddUserClick = () => {
    setUserModalTitle('New User');
    setUserModalEdit(false);
    setShowUserModal(true);
  }

  const handleDelete = (id, name) => {
    setDeleteId(id);
    setConfirmDlgText(`Are you sure you want to delete the user ${name}`);
    setShowConfirmationDialog(true);
  };

  const handleEdit = (data) => {
    setUserModalTitle('Edit user');
    setUserModalData(data);
    setUserModalEdit(true);
    setShowUserModal(true);
  }

  const handleSetPassword = (id) => {
    setPasswordId(id);
    setShowPasswordModal(true);
  }

  const handleSetRole = (id, role) => {
    const newLocalUsers = localUsers.map( (lu, index) => {
      if (lu.id === id) return {...lu, role: role}; else {return {...lu}};
    });
    setLocalUsers(newLocalUsers);
  }

  const handleSetEnable = (id, enabled) => {
    //console.log('setting enabled to ', enabled)
    //update UI
    const newLocalUsers = localUsers.map( (lu, index) => {
      if (lu.id === id) return {...lu, enabled: enabled}; else {return {...lu}};
    })

    setLocalUsers(newLocalUsers);
  }

  const deleteUser = () => {
    setShowConfirmationDialog(false);

    fetch(`/api/users/${deleteId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `kaToken ${token}`,
      },
    })
      .then((res) => {
        if (res.ok) return res.json();
        showMessage('danger','Error deleting user.')
      })
      .then((data) => {

        if (data.code && data.code === 200) {
          const newLocalUsers = localUsers.filter((user) => {
            return !(user.id === deleteId);
          });

          setLocalUsers(newLocalUsers);
          showMessage('success','User deleted succesfully.')
          //onUsersChange(true);

        } else showMessage('danger','Error deleting user.');
      });

    
  };

  async function handleNewUserSubmit(user)  {

    if (userModalEdit) {

      fetch(`/api/users/${user.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `kaToken ${token}`,
        },
        body: JSON.stringify(user),
      })
      .then( res => {
          if (res.ok) return res.json()
          showMessage('danger','Error editing user.')
      } )
      .then( data => {
          if (data.code && data.code === 200 ) { 
              //update UI
              const index = localUsers.findIndex( (localUser) => {
                return localUser.id === user.id
              })

              localUsers[index] = {...user};

              showMessage('success','User modified succesfully.')

          } else showMessage('danger','Error editing user.')
      } )

    } else {

        try {
          const res = await signUp(user)
          if (res.code === 200) {
            showMessage('success','User created succesfully.')
            const newUsers = [...localUsers, user]
            setLocalUsers(newUsers);
          } else {
            showMessage('danger','Error creating new user.')
          }
        } catch (e) {
          showMessage('danger','Error creating new user.')
        }
        
    }

    setUserModalEdit(false);
    setShowUserModal(false);
  }

  const handlePasswordSubmit = (id, password) => {

    setShowPasswordModal(false);

    fetch(`/api/users/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `kaToken ${token}`,
      },
      body: JSON.stringify({ password: password }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        showMessage('danger','Error setting password.')
      })
      .then((data) => {
        if (data.code && data.code === 200) {
          showMessage('success','Password set succesfully.')
        } else showMessage('danger','Error setting password.')
      });

    setPasswordId(-1);
  }

  
  return (
    <>
      <Card className="my-3 overflow-auto">
        <Card.Header
          className="d-flex align-items-end"
          style={{ backgroundColor: "#4d5b7e" }}
        >
          <h5 className="text-white">{title}</h5>

          <Form.Control
            className="ms-auto"
            type="search"
            placeholder="Search"
            style={{ width: "30%" }}
            onChange={handleSearch}
          />

          <Button
            className="ms-2 d-flex align-items-end"
            variant="outline-light"
            onClick={handleAddUserClick}
          >
            <span className="material-icons me-3">person_add_alt</span>
            <span>New User</span>
          </Button>
        </Card.Header>

        <Card.Body>
          <Table hover>
            <thead>
              <tr>
                <th>No.</th>
                <th
                  onClick={() => setSortedField("company")}
                  style={{ cursor: "pointer" }}
                >
                  Company
                  {sortedField === "company" && (
                    <span
                      className="material-icons"
                      style={{ fontSize: "20px" }}
                    >
                      arrow_drop_down
                    </span>
                  )}
                </th>
                <th
                  onClick={() => setSortedField("username")}
                  style={{ cursor: "pointer" }}
                >
                  Name
                  {sortedField === "username" && (
                    <span
                      className="material-icons"
                      style={{ fontSize: "20px" }}
                    >
                      arrow_drop_down
                    </span>
                  )}
                </th>
                <th
                  onClick={() => setSortedField("userlastname")}
                  style={{ cursor: "pointer" }}
                >
                  Last Name
                  {sortedField === "userlastname" && (
                    <span
                      className="material-icons"
                      style={{ fontSize: "20px" }}
                    >
                      arrow_drop_down
                    </span>
                  )}
                </th>
                <th
                  onClick={() => setSortedField("email")}
                  style={{ cursor: "pointer" }}
                >
                  Email
                  {sortedField === "email" && (
                    <span
                      className="material-icons"
                      style={{ fontSize: "20px" }}
                    >
                      arrow_drop_down
                    </span>
                  )}
                </th>
                <th
                  onClick={() => setSortedField("role")}
                  style={{ cursor: "pointer" }}
                >
                  Role
                  {sortedField === "role" && (
                    <span
                      className="material-icons"
                      style={{ fontSize: "20px" }}
                    >
                      arrow_drop_down
                    </span>
                  )}
                </th>
                <th
                  onClick={() => setSortedField("enabled")}
                  style={{ cursor: "pointer"}}
                >
                  Active
                  {sortedField === "enabled" && (
                    <span
                      className="material-icons"
                      style={{ fontSize: "20px" }}
                    >
                      arrow_drop_down
                    </span>
                  )}
                </th>
                <th className="text-end">Actions</th>
              </tr>
            </thead>

            <tbody>
              {localUsers.length > 0 ? ( //former currentUsers
                localUsers //former currentUsers
                .filter( u => `${u.company.toUpperCase()} ${u.username.toUpperCase()} ${u.userlastname.toUpperCase()} ${u.email.toUpperCase()}`.includes(search))
                .sort((a, b) => {
                  if (sortedField === 'enabled') {
                    if (a['enabled'] && b['enabled']) {return -1} else {return 1}
                  } else {
                    if (a[sortedField].toUpperCase() < b[sortedField].toUpperCase()) {return -1} else {return 1} 
                  }
                })
                .map((user, index) => {
                  user.index = index + 1;
                  //console.log(index, user); // delete after debug
                  return (
                    <User
                      key={index}
                      data={user}
                      onEdit={handleEdit}
                      onDelete={handleDelete}
                      onSetPassword={handleSetPassword}
                      onSetRole={handleSetRole}
                      onSetEnable={handleSetEnable}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan="8" className="text-center py-3">
                    No Data
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <ConfirmationDialog
        text={confirmDlgText}
        show={showConfirmationDialog}
        onYes={deleteUser}
        onHide={() => {
          setDeleteId(-1);
          setShowConfirmationDialog(false);
        }}
      />

      <ToastContainer className="p-3" position="top-center">
        <Toast
          show={showToast}
          bg={toastBg}
          delay={2000}
          autohide
          onClose={() => setShowToast(false)}
        >
          {/* <Toast.Header>
            <strong className="me-auto">Message</strong>
          </Toast.Header> */}
          <Toast.Body className="text-white text-center">
            {toastMessage}
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <UserModal
        show={showUserModal}
        title={userModalTitle}
        onCancel={() => {
          setShowUserModal(false);
        }}
        onSubmit={handleNewUserSubmit}
        edit={userModalEdit}
        data={userModalData}
      />

      <PasswordModal
        show={showPasswordModal}
        onCancel={() => {
          setShowPasswordModal(false);
        }}
        onSubmit={handlePasswordSubmit}
        id={passwordId}
      />
    </>
  );
};

export default UserList;
