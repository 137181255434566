// import DownloadPage from "../components/DownloadPage";
//
// const ServiceManualPage = () => {
//     return (
//         <DownloadPage title='Service Manuals' queryUrl='/api/files/service_manuals'/>
//     )
// }

import FileContextProvider from "../contexts/FileContext";
import Page from "../components/Page";
import FileManager from "../components/FileManager";

const ServiceManualPage = () => {

  return (
    <Page title="Service Manuals">
      <FileContextProvider>
        <FileManager dir={"Service_manuals"} view='file'/>
      </FileContextProvider>
    </Page>
  )

};

export default ServiceManualPage;
