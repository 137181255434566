import { ReactComponent as Rma } from './rma_logo.svg';
import { ReactComponent as Po } from './po_logo.svg';
import { ReactComponent as Download } from './download_logo.svg';
import { ReactComponent as AppNotes } from './app_notes_logo.svg';
import { ReactComponent as SrvManual } from './service_manual_logo.svg';
import { ReactComponent as Walkie } from './walkie5.svg';
import { ReactComponent as Users } from './users.svg';
import { ReactComponent as Settings } from "./settings.svg";
import { ReactComponent as Charts } from "./charts.svg";
import { ReactComponent as Pictures } from "./pictures.svg";
import { ReactComponent as Folder } from "./folder.svg";
import { ReactComponent as Brochure } from "./brochure.svg";
import { ReactComponent as Pricebook } from "./pricebook.svg";
import { ReactComponent as PdfLogo } from "./pdf_logo.svg";
import { ReactComponent as ExcelLogo } from "./excel_logo.svg";

export default {
    Rma,
    Po,
    Download,
    AppNotes,
    SrvManual,
    Walkie,
    Users,
    Settings,
    Charts,
    Pictures,
    Folder,
    Brochure,
    Pricebook,
    PdfLogo,
    ExcelLogo
}
