async function apiRequest(endPoint, method, token, data, onSuccess, onFail) {
  try {
    const response = await fetch(endPoint, {
      method: method,
      headers: {
        "Content-type": "application/json",
        Authorization: `kaToken ${token}`,
      },
      body: data && JSON.stringify(data),
    });
    const fetchedData = await response.json(response);
    if (fetchedData.code > 200) onFail(fetchedData);
    else onSuccess(fetchedData.data);
  } catch (error) {
    onFail({
      code: error,
      message: `Error  at endpoint ${endPoint}`,
    });
  }
}

export default apiRequest;
