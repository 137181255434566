import { Modal, Form, Button, Stack, Row, Col } from 'react-bootstrap';
import { useRef } from 'react';


const ShipAddressModal = ({show, onSubmit, onCancel, edit, shipAddr}) => {

    const nameRef = useRef();
    const addressRef = useRef();
    const cityRef = useRef();
    const stateRef = useRef();
    const zipRef = useRef();
    const countryRef = useRef();
    const phoneRef = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            id: shipAddr.id,
            customerid: shipAddr.customerid,
            name: nameRef.current.value,
            address: addressRef.current.value,
            city: cityRef.current.value,
            state: stateRef.current.value,
            zip: zipRef.current.value,
            country: countryRef.current.value,
            phone: phoneRef.current.value
        }
        onSubmit(data);
    }

    const handleCancelClick = () => {
        onCancel();
    }

    return (
      <Modal show={show} onHide={onCancel}>
        <Modal.Header
          closeButton
          className="text-white"
          style={{ backgroundColor: "#4D5B7E" }}
        >
          <Modal.Title>
            {edit ? "Modify Shipping Address" : "New Shipping Address"}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                required
                ref={nameRef}
                autoComplete="name"
                maxLength={30}
                defaultValue={edit ? shipAddr.name : ''}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="address">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                required
                ref={addressRef}
                autoComplete="address"
                maxLength={255}
                defaultValue={edit ? shipAddr.address : ''}
              />
            </Form.Group>

            <Row>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={30}
                    ref={cityRef}
                    defaultValue={edit ? shipAddr.city : ''}
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group className="mb-3" controlId="state">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={30}
                    ref={stateRef}
                    defaultValue={edit ? shipAddr.state : ''}
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group className="mb-3" controlId="zip">
                  <Form.Label>Zip</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={10}
                    ref={zipRef}
                    defaultValue={edit ? shipAddr.zip : ''}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="country">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={30}
                    ref={countryRef}
                    defaultValue={edit ? shipAddr.country : ''}
                  />
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3" controlId="phone">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="tel"
                    required
                    maxLength={16}
                    ref={phoneRef}
                    defaultValue={edit ? shipAddr.phone : ''}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Stack direction="horizontal">
              <Button
                className="ms-auto"
                variant="secondary"
                type="reset"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
              <Button className="ms-3" variant="primary" type="submit">
                OK
              </Button>
            </Stack>
          </Form>
        </Modal.Body>
      </Modal>
    );
}

export default ShipAddressModal;