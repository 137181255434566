import './RmaDetail.css';
import Logo from "../kirisun-americas-logo.png";
import { Card, Stack, Row, Col, Table, Button, ButtonGroup, Toast, ToastContainer, Dropdown } from 'react-bootstrap';
import { useAuth } from "../contexts/AuthContext";
import Product from './Product';
import React, {useContext, useState, useEffect, useRef} from 'react';
import {RmaContext} from '../contexts/RmaContext';
import { PDFDownloadLink } from '@react-pdf/renderer';
import RmaPdf from './RmaPdf';
import IconState from "./IconState";
import DateModal from "./DateModal";
import Barcode from 'react-barcode';


const RmaDetail = ( {rma, onPdf, onClose} ) => {

    const { currentUser, isAllowedRole, getUserInfo } = useAuth();
    const {shippingAddress, patchRma} = useContext(RmaContext);
    const [currentShippingAddress, setCurrentShippingAddress] = useState({
      id: -1,
      customerid: rma.userid,
      address: "",
      city: "",
      zip: "",
      country: "",
      state: "",
      phone: "",
      name: "",
    });
    const rmaId = rma.id;
    const [rmaCurrentStatus, setRmaCurrentStatus] = useState(rma.status);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [dateModalShow, setDateModalShow] = useState(false);
    const [dateModalTitle, setDateModalTitle] = useState('');
    const [rmaRequestState, setRmaRequestState] = useState(null);
    const [barcodeImgSrc, setbarcodeImgSrc] = useState(null);

    const barcodeRef = useRef(null);

    

    const handleViewPdf = () => {
      onPdf(currentShippingAddress, barcodeImgSrc)
    }

    const handleProductError = (error) => {
      setErrorMessage(error);
      setShowError(true);
    }

    //Set Status

    const handleSetReceived = () => {
      //Launch modal dialog asking for the date
      setDateModalTitle('Enter RMA received date');
      setRmaRequestState('received');
      setDateModalShow(true);
    }

    const handleSetProcessing = () => {
      //Launch modal dialog asking for the date
      setDateModalTitle('Enter RMA start processing date');
      setRmaRequestState('progress');
      setDateModalShow(true);
    }

    const handleSetWaitingForReply = () => {
      //Launch modal dialog asking for the date
      setDateModalTitle('Enter RMA waiting for reply date');
      setRmaRequestState('waiting');
      setDateModalShow(true);
    }

    const handleSetWaitForDelivery = () => {
      //Launch modal dialog asking for the date
      setDateModalTitle('Enter RMA waiting for delivery date');
      setRmaRequestState('pending');
      setDateModalShow(true);
    }

    const handleSetReturned = () => {
      //Launch modal dialog asking for the date
      setDateModalTitle('Enter RMA returned date');
      setRmaRequestState('returned');
      setDateModalShow(true);
    }

    const dateModalSubmitHandler = (e) => {
      e.preventDefault();
      const date = e.target.elements['date'].value;
      setDateModalShow(false);
      changeRmaStatus(rmaRequestState, date);
    }

    const changeRmaStatus = (newState, date) => {
      
      console.log(newState, date);

      let field = '';

      switch(newState) {
        case 'received': field = 'received'; break;
        case 'progress': field = 'processing'; break;
        case 'waiting': field = 'waiting'; break;
        case 'pending': field = 'todeliver'; break;
        case 'returned': field = 'returned'; break;
        default: field = ''; break;
      }

      let patchObj = {};
      patchObj[`${field}`] = `${date}`;
      patchObj['status'] = newState;

      console.log(patchObj);

      console.table(rma);

      patchRma(
        rma, 
        patchObj,
        (resData) => {
          // MessageToast("Rma - Date received updated", "success");
          console.log('RMA status updated successfully.');
          setRmaCurrentStatus(newState);
        },
        (error) => {
          // MessageToast(
          //   `Rma - Error updating received date. ${error.message}`,
          //   "danger"
          // );
          console.error('Error updating RMA status', error.message);
        }
      );

    }

    //end set status


    useEffect(()=>{

      //Capturing the Barcode image
      const svg = document.querySelector('svg');
      if (svg) {
        const serializer = new XMLSerializer(); 
        const svgStr = serializer.serializeToString(svg); 
        const imgSvgSrc = `data:image/svg+xml;base64,${window.btoa(svgStr)}`; 

        setbarcodeImgSrc(imgSvgSrc);
      }

      // if (barcodeRef.renderElementRef !== null) {
      // const barcodeSvg = barcodeRef.current; console.log(barcodeRef.current);
      // const serializer = new XMLSerializer();
      // const svgStr = serializer.serializeToString(barcodeSvg);
      // const imgSvgSrc = `data:image/svg+xml;base64,${window.btoa(svgStr)}`; 
      // setbarcodeImgSrc(imgSvgSrc);
      // }

      //console.log(rma);

      if (rma.shipingaddr === -1 && isAllowedRole) {
        getUserInfo(
          rma.userid,
          (user) => {
            setCurrentShippingAddress({
              id: -1,
              customerid: rma.userid,
              address: user.address,
              city: user.city,
              zip: user.zip,
              country: user.country,
              state: user.state,
              phone: user.phone,
              name: user.company,
            });
          },
          (error) => {
            alert(error.message);
          }
        );
      } else {
        const ca = shippingAddress.find((addr) => addr.id === rma.shipingaddr);
        setCurrentShippingAddress(ca);
      }
    }, []);
    

    
        return (
          <>
            <Card className="p-3 paper shadow" style={{ marginBottom: "60px" }}>

              <Row>
                <Col>
                  <Row><Col><b>RMA No.:</b> {rma.rmano}</Col></Row>
                  <Row><Col><b>PO/Ref:</b> {rma.poref}</Col></Row>
                </Col>
                
                <Col>
                  <Row><Col><b>Filled by:</b> {rma.filedby}</Col></Row>
                  <Row>
                    <Col md="auto"><b>Ship To:</b></Col>
                    <Col md="auto">
                      <Row>{currentShippingAddress.name}</Row>
                      <Row>{currentShippingAddress.address}</Row>
                      <Row>
                        {currentShippingAddress.city},{" "}
                        {currentShippingAddress.state},{" "}
                        {currentShippingAddress.zip},{" "}
                        {currentShippingAddress.country}
                      </Row>
                    </Col>
                  </Row>
                </Col>
                
                <Col>
                  <Row><Col><b>Date: </b>{rma.date}</Col></Row>
                  <Row><Col><b>Company: </b>{rma.fromcompany}</Col></Row>
                  <Row><Col><b>Satus: </b>  <IconState state={rmaCurrentStatus} showLabel={true} /></Col></Row>
                </Col>
                
                <Col>
                  <Row><Barcode height={30} width={1} value={rma.rmano} ref={barcodeRef}/></Row>
                </Col>
              </Row>
              
              
              <Row className="mb-3">
                <Col>
                  <b>Comments: </b>
                  {rma.comments}
                </Col>
              </Row>

              <Table bordered striped responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Product</th>
                    <th>Serial No.</th>
                    <th>Reason of return</th>
                    <th>FW/SW Ver.</th>
                    <th>DOA</th>
                    <th>Warranty</th>
                    <th>Repaired</th>
                    <th>Replaced</th>
                    {isAllowedRole && <th className="text-center">Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {rma.products.map((prod, index) => {
                    index++;
                    return (
                      <Product
                        key={index}
                        data={{ index, rmaId, ...prod }}
                        actions={isAllowedRole}
                        onError={handleProductError}
                      />
                    );
                  })}
                </tbody>
              </Table>

              <div className="d-flex justify-content-end">
              
                { isAllowedRole && (  
                <Dropdown as={ButtonGroup} className="me-2">
                  <Button variant='secondary'>Set status</Button>
                  <Dropdown.Toggle split variant="secondary" id="dropdown-set-rma-status"/>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleSetReceived}>Received</Dropdown.Item>
                    <Dropdown.Item onClick={handleSetProcessing}>Processing</Dropdown.Item>
                    <Dropdown.Item onClick={handleSetWaitingForReply}>Waiting for reply</Dropdown.Item>
                    <Dropdown.Item onClick={handleSetWaitForDelivery}>Waiting for delivery</Dropdown.Item>
                    <Dropdown.Item onClick={handleSetReturned}>Returned</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                )}

                <PDFDownloadLink
                  document={
                    <RmaPdf rma={rma} address={currentShippingAddress} />
                  }
                  fileName={"RMA." + rma.rmano + ".pdf"}
                >
                  <Button variant="primary">
                    <span className="material-icons-outlined fs-6 me-2">
                      file_download
                    </span>
                    Download Pdf
                  </Button>
                </PDFDownloadLink>

                {/*****  View PDF  ***************** */}
                <Button
                  variant="primary"
                  className="ms-2"
                  onClick={handleViewPdf}
                >
                  <span className="material-icons-outlined fs-6 me-2">
                    print
                  </span>
                  View Pdf
                </Button>

                {/* ********** Close RMA Detail ********** */}
                <Button
                  variant="danger"
                  className="ms-2"
                  type="cancel"
                  onClick={onClose}
                >
                  <span className="material-icons-outlined fs-6 me-2">
                    close
                  </span>
                  Close
                </Button>

              </div>
            </Card>

            <ToastContainer position="middle-center">
              <Toast
                show={showError}
                bg="danger"
                onClose={() => setShowError(false)}
              >
                <Toast.Header>
                  <strong className="me-auto">Error !</strong>
                </Toast.Header>
                <Toast.Body className="text-white">{errorMessage}</Toast.Body>
              </Toast>
            </ToastContainer>

            <DateModal
              title={dateModalTitle}
              show={dateModalShow}
              onHide={() => setDateModalShow(false)}
              onSubmit={dateModalSubmitHandler}
            />
          </>
        );
}

export default RmaDetail;