import Page from "../components/Page";
import PriceBook from '../components/PriceBook';
import FileContextProvider from "../contexts/FileContext";

const PricebookPage = () => {

    return (
      <Page title="Price Book">
        <FileContextProvider>
            <PriceBook/>
        </FileContextProvider>
      </Page>
    )
  
  };
  
  export default PricebookPage;