import React, { useState } from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useAuth } from "../contexts/AuthContext";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <>
    <span 
        ref={ref}
        className="material-icons" style={{cursor: 'pointer', color: 'blue'}}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
    >
            more_vert
    </span>
    {children}
    </>
  ));

const User = ({data, onEdit, onDelete, onSetPassword, onSetRole, onSetEnable}) => {

    const [localData, setLocalData] = useState(data);
    const { token, currentUser } = useAuth();

    function patchUser(body, onSuccess, onError) {
        
        fetch(`/api/users/${data.id}`, {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `kaToken ${token}`,
          },
          body: JSON.stringify(body),
        })
        .then( res => {
            if (res.ok) return res.json()
            onError(res)
        } )
        .then( data => {
            if (data.code && data.code === 200 ) { 
                onSuccess()
            } else onError(data)
        } )
    }

    const handleActivateClick = (e) => {
        e.preventDefault()

        //call API to set user active
        //if operation succesfull change status on UI
        patchUser({"enabled": true}, ()=>{
            // setLocalData({
            //   ...localData,
            //   enabled: true,
            // });
            onSetEnable(data.id, true);
        }, (error) => {
            error.log(error)
        } )
        
    }

    const handleDeactivateClick = (e) => {
        e.preventDefault()

        //call API to set user active
        //if operation succesfull change status on UI

        patchUser(
          { enabled: false },
          () => {
            // setLocalData({
            //   ...localData,
            //   enabled: false,
            // });
            onSetEnable(data.id, false)
          },
          (error) => {
            error.log(error);
          }
        );

    }

    const handleUserClick = (e) => {  //Change role to user
        e.preventDefault()

        patchUser({role: 'user'}, () => {
            // setLocalData({
            //     ...localData,
            //     role: 'user'
            // })
            onSetRole(data.id, 'user')
        }, (error)=>{error.log(error)})

        
    }

    const handleAdminClick = (e) => {  //Change role to admin
        e.preventDefault()

        patchUser(
          { role: 'admin' }, () => {
            onSetRole(data.id, 'admin')
          } 
          ,
          (error) => {
            error.log(error);
          }
        );
    }

    const handleEditClick = (e) => {
        e.preventDefault()
        onEdit(data)
    }

    const handleDeleteClick = (e) => {
        e.preventDefault()
        onDelete(data.id, data.username + ' ' + data.userlastname)
    }

    const handleSetPasswordClick = (e) => {
        e.preventDefault()
        onSetPassword(data.id)
    }

    return(
        <tr>
            <td>{data.index}</td>
            <td>{data.company}</td>
            <td>{data.username}</td>
            <td>{data.userlastname}</td>
            <td>{data.email}</td>
            <td>{data.role}</td>
            <td className="text-center">{data.enabled ? <span className="material-icons">check</span> : <span className="material-icons">clear</span> }</td>
            <td className="text-end  justify-content-end">
                <OverlayTrigger
                    placement='bottom'
                    overlay={
                        <Tooltip>Edit user</Tooltip>
                    }
                >
                    <span
                        onClick={handleEditClick}
                        className="material-icons"
                        style={{
                            cursor: 'pointer',
                            color: 'green'
                        }}
                    >
                        edit
                    </span>
                </OverlayTrigger>

                <OverlayTrigger
                    placement='bottom'
                    overlay={
                        <Tooltip>Delete user</Tooltip>
                    }
                >
                    <span
                        onClick={handleDeleteClick}
                        className="material-icons"
                        style={{
                            cursor: 'pointer',
                            color: 'red'
                        }}
                    >
                        delete
                    </span>    
                </OverlayTrigger>

                <Dropdown className="d-inline-block">
                    <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
                    <Dropdown.Menu drop='start'>
                        <Dropdown.Header>Set Active</Dropdown.Header>
                        <Dropdown.Item eventKey="1" onClick={handleActivateClick}>Activate</Dropdown.Item>
                        <Dropdown.Item eventKey="2" onClick={handleDeactivateClick}>Deactivate</Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Header>Roles</Dropdown.Header>
                        <Dropdown.Item eventKey="3" onClick={handleUserClick}>Set as User</Dropdown.Item>
                        <Dropdown.Item eventKey="4" onClick={handleAdminClick}>Set as Admin</Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item eventKey="5" onClick={handleSetPasswordClick} >Set password</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> 
            </td>
        </tr>
    )
}

export default User;