import { Container } from 'react-bootstrap';

export default function Footer() {
    return (
        <div className='no-print'>
        {/* <footer className='fixed-bottom' style={{background: '#C7C8C9'}}> */}
        <footer style={{background: '#C7C8C9', position: 'fixed', right: 0, left: 0, bottom: 0, zIndex: 1030}}>
            <Container className='py-2 d-flex justify-content-center'>
                <span>Copyright. Kirisun Americas {'(2019 - ' + (new Date()).getFullYear()+')'}.</span>
            </Container>
        </footer>
        </div>
    )
}