import {
    Card,
    Row,
    Col,
    Form
} from 'react-bootstrap';

const AdminSettings = () => {
    return (
      <div style={{ padding: "1rem", height: "400px", overflow: "auto" }}>
        <Row className="mb-3">
          <Col lg={4} md={6} className="mb-3">
            <Card style={{ height: "100%", background: "#F9F9F9" }}>
              <Card.Body>
                <Card.Title>Admin account</Card.Title>
                <Form.Group className="mb-3">
                  <Form.Label>Root user</Form.Label>
                  <Form.Control type="text" defaultValue="root" />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Root password</Form.Label>
                  <Form.Control type="text" defaultValue="Rootp@55word" />
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={4} md={6} className="mb-3">
            <Card style={{ height: "100%", background: "#F9F9F9" }}>
              <Card.Body>
                <Card.Title>Email sender</Card.Title>
                <Form.Group className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue="root@kirisunamericas.com"
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Email password</Form.Label>
                  <Form.Control type="text" defaultValue="Rootp@55word" />
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={4} md={6} className="mb-3">
            <Card style={{ height: "100%", background: "#F9F9F9" }}>
              <Card.Body>
                <Card.Title>Email CC destinatary</Card.Title>
                <Form.Group>
                  <Form.Label>Destiny addresses</Form.Label>
                  <Form.Select htmlSize={5}>
                    <option>joed.machado@kirisunamericas.com</option>
                    <option>carlos.chajin@kirisunamericas.com</option>
                    <option>info@kirisunamericas.com</option>
                  </Form.Select>
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={4} md={6} className="mb-3">
            <Card style={{ height: "100%", background: "#F9F9F9" }}>
              <Card.Body>
                <Card.Title>Download files path</Card.Title>
                <Form.Group>
                  <Form.Label>Root files path</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue="\my.kirisunamericas.com\files"
                  />
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>

          <Col lg={4} md={6} className="mb-3">
            <Card style={{ height: "100%", background: "#F9F9F9" }}>
              <Card.Body>
                <Card.Title>SMTP Server</Card.Title>
                <Form.Group>
                  <Form.Label>Server address</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue="smtp.office365.com"
                  />
                </Form.Group>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    );
}

export default AdminSettings;