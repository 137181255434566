import { Card, Nav} from 'react-bootstrap'; 
import Icon from '../components/Icon/';
import React, {useContext} from 'react';
import { FileContext } from '../contexts/FileContext';

const Pricebook = () => {

    const {downloadPricebook} = useContext(FileContext);

    const handleClick = (e) => {
        console.log(e.target.getAttribute('file'));
        const file = e.target.getAttribute('file');
        downloadPricebook(file);
    }

    return (
        <>
        <Card>

          <Card.Body>
            <Card.Title>Here you can download the updated price list for the Kirisun Americas's products.</Card.Title>
            <Card.Subtitle>Last updated: 7/15/2024</Card.Subtitle>
                    <Nav className="flex-column mt-3">
                        <div className="d-flex align-items-center">
                          <Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/>
                          <Nav.Link file='Kirisun_Americas_Analogue_Price_Book_Dealer_2024.pdf' onClick={handleClick}>Kirisun Americas Dealer's Analogue price book. PDF format.</Nav.Link>
                        </div>
                        
                        <div className="d-flex align-items-center">
                          <Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/>
                          <Nav.Link file='Kirisun_Americas_DMR_Price_Book_Dealer_2024.pdf' onClick={handleClick}>Kirisun Americas Dealer's DMR price book. Excel format.</Nav.Link>
                        </div>

                        <div className="d-flex align-items-center">
                          <Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/>
                          <Nav.Link file='Kirisun_Americas_PTToC_Dealer_Price_Book_2024.pdf' onClick={handleClick}>Kirisun Americas Dealer's PTToC price book. PDF format.</Nav.Link>
                        </div>
                        
                        <div className="d-flex align-items-center">
                          <Icon.ExcelLogo className='ms-3' style={{width:'20px', height:'20px'}}/>
                          <Nav.Link file='Kirisun_Americas_Delaers_PriceBook_2024_REV1.18_7.11.2024.xlsx' onClick={handleClick}>Kirisun Americas Dealer's price book. Excel format.</Nav.Link>
                        </div>
                    </Nav>
          </Card.Body>

        </Card>
        </>
    )
  
  };
  
  export default Pricebook;