import React, {useContext} from 'react';
import { Card, Nav, Row, Col } from "react-bootstrap";
import Icon from '../components/Icon/';
import { FileContext } from '../contexts/FileContext';

const Brochures = () => {

    const {downloadBrochure} = useContext(FileContext);

    const handleClick = (e) => {
        console.log(e.target.getAttribute('file'));
        const file = e.target.getAttribute('file');
        downloadBrochure(file);
    }

    return( 
        <>
            <Card className="shadow-sm">
            <Card.Header>Analog</Card.Header>
            <Card.Body>
                <Row>
                    <Nav>
                        <Col sm={3} className='d-flex align-items-center'>
                            <Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/>
                            <Nav.Link  file='PT370_Brochure.pdf' onClick={handleClick}>PT370 Brochure</Nav.Link>
                        </Col>
                        <Col sm={3} className='d-flex align-items-center'>
                            <Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/>
                            <Nav.Link file='PT570_Brochure.pdf' onClick={handleClick}>PT570 Brochure</Nav.Link>
                        </Col>
                        <Col sm={3} className='d-flex align-items-center'>
                            <Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/>
                            <Nav.Link file='PT3600_Brochure.pdf' onClick={handleClick}>PT3600 Brochure</Nav.Link>
                        </Col>
                    </Nav>
                </Row>
            </Card.Body>
        </Card>
        
        <Card className="mt-4 shadow-sm">
            <Card.Header>DMR</Card.Header>
            <Card.Body>
               <Row>
                    <Nav>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='DP405_brochure.pdf' onClick={handleClick}>DP405 Brochure</Nav.Link></Col>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='DM588_brochure.pdf' onClick={handleClick}>DM588 Brochure</Nav.Link></Col>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='DP480_485_brochure.pdf' onClick={handleClick}>DP480/485 Brochure</Nav.Link></Col>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='DP580_585_brochure.pdf' onClick={handleClick}>DP580/585 Brochure</Nav.Link></Col>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='DP990_995_brochure.pdf' onClick={handleClick}>DP990/995 Brochure</Nav.Link></Col>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='DP680_685_brochure.pdf' onClick={handleClick}>DP680/685 Brochure</Nav.Link></Col>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='DP810_815_brochure.pdf' onClick={handleClick}>DP810/815 Brochure</Nav.Link></Col>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='TM840_brochure.pdf' onClick={handleClick}>TM840 Brochure</Nav.Link></Col>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='UP305_brochure.pdf' onClick={handleClick}>UP305 Brochure</Nav.Link></Col>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='UP405S_brochure.pdf' onClick={handleClick}>UP405S Brochure</Nav.Link></Col>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='DR600_brochure.pdf' onClick={handleClick}>DR600 Brochure</Nav.Link></Col>
                    </Nav> 
                </Row> 
            </Card.Body>
        </Card>

        <Card className="mt-4 shadow-sm">
            <Card.Header>PTToC</Card.Header>
            <Card.Body>
                <Row>
                    <Nav>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='T60Y_65Y_brochure.pdf' onClick={handleClick}>T60Y/65Y Brochure</Nav.Link></Col>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='GP700_brochure.pdf' onClick={handleClick}>GP700 Brochure</Nav.Link></Col>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='T350_brochure.pdf' onClick={handleClick}>T350 Brochure</Nav.Link></Col>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='M50-4G_brochure.pdf' onClick={handleClick}>M50 4G Brochure</Nav.Link></Col>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='MD200_brochure.pdf' onClick={handleClick}>MD200 Brochure</Nav.Link></Col>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='TM840-GW_brochure.pdf' onClick={handleClick}>TM840-GW Brochure</Nav.Link></Col>
                    </Nav>
                </Row>
            </Card.Body>
        </Card>

        <Card className="mt-4 shadow-sm">
            <Card.Header>PTToC - iCallX</Card.Header>
            <Card.Body>
            <Row>
                    <Nav>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='iCallX_T60Y_T65Y_brochure.pdf' onClick={handleClick}>T60Y/65Y Brochure</Nav.Link></Col>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='iCallX_GP700_brochure.pdf' onClick={handleClick}>GP700 Brochure</Nav.Link></Col>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='iCallX_T350_brochure.pdf' onClick={handleClick}>T350 Brochure</Nav.Link></Col>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='iCallX_M50_brochure.pdf' onClick={handleClick}>M50 4G Brochure</Nav.Link></Col>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='iCallX_KD200_brochure.pdf' onClick={handleClick}>KD200 Brochure</Nav.Link></Col>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='iCallX_TM840-GW_brochure.pdf' onClick={handleClick}>TM840-GW Brochure</Nav.Link></Col>
                        <Col sm={3} className='d-flex align-items-center'><Icon.PdfLogo className='ms-3' style={{width:'20px', height:'20px'}}/><Nav.Link file='iCallX_PTToC_solution_brochure.pdf' onClick={handleClick}>PTToC Solution Brochure</Nav.Link></Col>
                    </Nav>
                </Row>
            </Card.Body>
        </Card>
        </>
    )
}

export default Brochures;