import Chart from "./Chart";

class BarChart extends Chart {
  constructor(canvas, width, height, title, data) {
    super(canvas, width, height, title, data);
    this.paddingX = 120;
    this.paddingY = 60;
    this.distance =
      (this.CANVAS_WIDTH - 2 * this.paddingX) / (this.data.length + 1);
    this.barWidth = this.distance;

    this.maxValue = this.data[0].value;
    for (let i = 1; i < this.data.length; i++) {
      if (this.data[i].value > this.maxValue)
        this.maxValue = this.data[i].value;
    }
    this.scale =
      (this.CANVAS_HEIGHT - 2 * this.paddingY) / Math.ceil(this.maxValue);
  }
  #draw_axis() {
    this.ctx.beginPath();
    this.ctx.strokeStyle = "black";
    this.ctx.moveTo(this.paddingX, this.paddingY);
    this.ctx.lineTo(this.paddingX, this.CANVAS_HEIGHT - this.paddingY);
    this.ctx.lineTo(
      this.CANVAS_WIDTH - this.paddingX,
      this.CANVAS_HEIGHT - this.paddingY
    );
    for (let i = 1; i <= this.data.length; i++) {
      this.ctx.moveTo(
        this.paddingX + this.distance * i,
        this.CANVAS_HEIGHT - this.paddingY
      );
      this.ctx.lineTo(
        this.paddingX + this.distance * i,
        this.CANVAS_HEIGHT - this.paddingY + 5
      );
    }

    const step = (this.CANVAS_HEIGHT - 2 * this.paddingY) / 10;
    for (
      let y =
        this.CANVAS_HEIGHT -
        this.paddingY -
        Math.ceil(this.maxValue) * this.scale;
      y < this.CANVAS_HEIGHT - this.paddingY;
      y += step
    ) {
      this.ctx.moveTo(this.paddingX, y);
      this.ctx.lineTo(this.paddingX - 10, y);
    }
    this.ctx.stroke();
    this.ctx.closePath();
  }
  draw() {
    super.draw();
    this.#draw_axis();
    for (let i = 0; i < this.data.length; i++) {
      this.ctx.fillStyle = this.data[i].color;
      this.ctx.fillRect(
        this.paddingX + this.distance * (i + 1) - this.barWidth / 2,
        this.CANVAS_HEIGHT - this.paddingY - this.data[i].value * this.scale,
        this.barWidth,
        this.data[i].value * this.scale
      );
      this.ctx.fillStyle = "black";
      this.ctx.font = "12px Arial";
      this.ctx.textAlign = "center";
      this.ctx.fillText(
        this.data[i].label,
        this.paddingX + this.distance * (i + 1),
        this.CANVAS_HEIGHT - this.paddingY + 15
      );
    }
  }
}

export default BarChart;
