import { Stack, Nav, Form } from "react-bootstrap";
import React, {useRef, useState, useContext, useEffect} from 'react';
import { RmaContext } from "../contexts/RmaContext";
import PieChartReact from "./PieChartReact";
import BarChartReact from "./BarChartReact";

const Reports = () => {

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec '];
    const colors = ['red', 'green', 'blue', 'yellow',
                    'orange', 'greenyellow', 'magenta','gold',
                    'tomato', 'lime', 'purple', 'darkkhaki',
                    'navy', 
                    'indigo', 
                    'steelblue', 
                    'cyan']
    const [activeComponent, setActiveComponent] = useState('ByModel');
    const { rmaYears, getReturnedByModel, getReturnedByCategory } = useContext(RmaContext);
    const [data, setData] = useState([]);
    const [chartTitle, setChartTitle] = useState(`Returned radios by model in ${2022}`);

    const yearRef = useRef();
    const monthRef = useRef();

    const handleValuesChange = () => {
        //setSelectedYear(yearRef.current.value);
        //setSelectedMonth(monthRef.current.value);
        const year = yearRef.current.value;
        const month = monthRef.current.value;
        if (activeComponent === 'ByModel') returnedByModel(year, month);
        if (activeComponent === "ByCategory") returnedByCategory(year, month);
        if (activeComponent === "ByCase") returnedByCondition(year, month);
        if (activeComponent === "Yearly") returnedYearly(year);
    }

    const handleModelsClick = () => {
        setActiveComponent("ByModel");
        returnedByModel(yearRef.current.value, monthRef.current.value);
    }

    const returnedByModel = (year, month) => {
        const mon = (month > 0) ? ` ${months[month-1]} / ` : '';
        setChartTitle(`Returned radios by model in ${mon} ${year}`);
        getReturnedByModel(
            year, 
            month, 
            data=>{ 
                const models = data.map( (model, index) => {
                    return {label: model.product, value: model.qty, color: colors[index]}
                });
                setData(models);
            }, 
            err=>{
                console.log(err);
            });
    }

    const handleCategoryClick = () => {
      setActiveComponent("ByCategory");
      returnedByCategory(yearRef.current.value, monthRef.current.value);
    };

    const returnedByCategory = (year, month) => {
        const mon = month > 0 ? ` ${months[month - 1]} / ` : "";
        setChartTitle(`Returned radios by category in ${mon} ${year}`);
        getReturnedByCategory(
            year, 
            month, 
            data=>{ 
                const categories = data.map( (cat, index) => {
                    return {label: cat.category, value: cat.qty, color: colors[index]}
                });
                setData(categories);
            }, 
            err=>{
                console.log(err);
            });
    }

    const returnedByCondition = (year, month) => {};

    const handleYearlyClick = () => {
        setActiveComponent("Yearly");
        returnedYearly(yearRef.current.value);
    }

    const returnedYearly = (year) => {
        setChartTitle(`Returned by month in ${year}`);
        setData([
          {
            label: "Jan",
            value: 23,
            color: colors[0],
          },
          {
            label: "Feb",
            value: 19,
            color: colors[1],
          },
          {
            label: "Mar",
            value: 32,
            color: colors[2],
          },
          {
            label: "Apr",
            value: 23,
            color: colors[3],
          },
          {
            label: "May",
            value: 23,
            color: colors[4],
          },
        ]);
    };



    useEffect(()=>{
        returnedByModel(2022, 0);
    }, [])

    return (
      <Stack direction="horizontal" style={{ height: "calc(100vh - 120px)" }}>
        <Nav
          variant="pills"
          defaultActiveKey="link-1"
          className="flex-column p-3 align-items-start h-100"
          style={{ background: "#E5E5E5" }}
        >
          <span>Returned by:</span>
          <Nav.Link
            eventKey="link-1"
            className="w-100"
            onClick={handleModelsClick}
          >
            Models
          </Nav.Link>
          <Nav.Link
            eventKey="link-2"
            className="w-100"
            onClick={handleCategoryClick}
          >
            Categories
          </Nav.Link>
          <Nav.Link
            eventKey="link-3"
            className="w-100"
            onClick={() => setActiveComponent("ByCase")}
          >
            Condition
          </Nav.Link>
          <Nav.Link
            eventKey="link-4"
            className="w-100"
            onClick={handleYearlyClick}
          >
            Yearly history
          </Nav.Link>

          <Form.Select className="mt-auto mb-2" ref={yearRef} onChange={handleValuesChange}>
            {rmaYears.map((year, index) => {
              return (
                <option key={index} value={year}>
                  {year}
                </option>
              );
            })}
          </Form.Select>

          <Form.Select ref={monthRef} onChange={handleValuesChange}>
            <option key={0} value="0">
              Whole year
            </option>
            {months.map((month, index) => {
              return (
                <option key={index} value={index+1}>
                  {month}
                </option>
              );
            })}
          </Form.Select>
        </Nav>

        <Stack className=" border bg-white">
          {activeComponent === "ByModel" && <PieChartReact title={chartTitle} data={data}/>}
          {activeComponent === "ByCategory" && <PieChartReact title={chartTitle} data={data}/>}
          {activeComponent === "ByCase" && (
            <div>
              Returned radios ordered by repaired, replaced or no issues found
            </div>
          )}
          {activeComponent === "Yearly" && <BarChartReact title={chartTitle} data={data}/>}
        </Stack>
      </Stack>
    );
}

export default Reports;