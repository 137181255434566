import {useState} from 'react';
import { Card } from 'react-bootstrap';
import './ShippingAddress.css'
import PopUpMenu from './PopUpMenu';

const ShippingAddress = ({data, onEdit}) => {

    const handleEditAddr = () => {
        onEdit(data);
    }

    const handleDelAddr = () => {
        alert(`About to DELETE address ${data.id}`);
    }

    const popUpMenuItems = [
      {
        header: false,
        title: "Modify",
        onClick: handleEditAddr,
      },
      {
        header: false,
        title: "Delete",
        onClick: handleDelAddr,
      }
    ]

    return(
        <Card>
            <Card.Header className='d-flex'>
                <Card.Title className='s-10 me-auto'>{data.name}</Card.Title>
                <PopUpMenu menuItems={popUpMenuItems}/>
            </Card.Header>

            <Card.Body>
                <div>{data.address}</div>
                <div>{data.city}, {data.state}</div>
                <div>{data.zip}, {data.country}</div>
            </Card.Body>
        </Card>
    )
}

export default ShippingAddress;