import React, { useRef, useState } from 'react';
import { Card, Form, Button, Alert, Spinner } from 'react-bootstrap';
import Logo from '../kirisun-americas-logo.png';
import { useAuth } from '../contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom'; 

export default function LoginForm({style}) {

    const emailRef = useRef();
    const passwordRef = useRef();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { logIn } = useAuth();
    const history = useHistory();

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setError('');
            setLoading(true);
            const success = await logIn(emailRef.current.value, passwordRef.current.value);
            setLoading(false);
            history.push('/');
        } catch (e) {
            setError('Failed to log in. If you\'ve just registered wait for account enable confirmation email.');
            setLoading(false);
        }    
        
    }

    return (
      <div style={style}>
        <Card
          //className="bg-primary bg-opacity-10 shadow p-4"
          className="shadow p-4"
          style={{ borderRadius: "10px", background: "#e6f0ff" }}
        >
          <Card.Body>
            <Card.Title className="mb-4 d-flex justify-content-between">
              <img
                src={Logo}
                width="130px"
                height="30px"
                className="d-inline-block align-top"
                alt="Kirisun Americas Logo"
              />
              {/* <h2>Log In</h2> */}
              <span
                className="material-icons-outlined"
                style={{
                  fontSize: "36px",
                  color: "#0D6EFD",
                }}
              >
                login
              </span>
            </Card.Title>
            <Form onSubmit={handleSubmit}>
              {error && <Alert variant="danger">{error}</Alert>}
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  required
                  ref={emailRef}
                  autoComplete="username"
                />
              </Form.Group>

              <Form.Group className="mb-4" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  required
                  ref={passwordRef}
                  autoComplete="current-password"
                />
              </Form.Group>

              <div className="w-100">
                <Button
                  disabled={loading}
                  className="w-100"
                  variant="primary"
                  type="submit"
                >
                  {loading && <Spinner animation='border' size='sm'/>}
                  Login
                </Button>
                <h6 className="mt-2 text-center">My Kirisun Americas V1.0</h6>
              </div>
            </Form>
          </Card.Body>
        </Card>

        <div className="w-100 text-center mt-2 text-white">
          Authorized dealer{" "}
          <Link to="/signup" className="text-warning">
            Sign Up
          </Link>
        </div>
      </div>
    );
}