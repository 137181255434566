import BarChart from "../libs/BarChart";
import React, { useRef, useEffect } from "react";

const BarChartReact = ({ title, data }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const chart = new BarChart(
      canvas,
      canvas.clientWidth,
      canvas.clientHeight,
      title,
      data
    );
    chart.draw();
  }, [data]);

  return (
    <canvas
      ref={canvasRef}
      style={{ width: "100%", height: "calc(100vh - 120px)" }}
    />
  );
};

export default BarChartReact;
