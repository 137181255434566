import PieChart from '../libs/PieChart';
import React, {useRef, useEffect} from 'react';


const PieChartReact = ({title, data}) => {

    const canvasRef = useRef(null);

    useEffect(()=>{
        const canvas = canvasRef.current;
        const chart = new PieChart(canvas, canvas.clientWidth, canvas.clientHeight, title, data);
        chart.draw();
    },[data]);

    return(
        <canvas ref={canvasRef} style={{width: '100%', height: 'calc(100vh - 120px)' }}/>
    )
}

export default PieChartReact;