import FileContextProvider from "../contexts/FileContext";
import Page from "../components/Page";
import FileManager from "../components/FileManager";
//import PictureGallery from "../components/PictureGallery";

const PicturesPage = () => {

  return (
    <Page title="HD Pictures">
      <FileContextProvider>
        <FileManager dir={"Pictures"} view='picture' />
        {/* <PictureGallery dir={'Pictures'}/> */}
      </FileContextProvider>
    </Page>
  )
  
};

export default PicturesPage;
