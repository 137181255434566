import {
    Modal,
    Form,
    Button
} from 'react-bootstrap';
import React, {useRef} from 'react';


const SnModal = ({show, serial, onSubmit, onHide}) => {

    const serialRef = useRef();

 return (
   <Modal show={show} onHide={onHide}>
     <Form onSubmit={onSubmit}>
       <Modal.Header closeButton>
         <Modal.Title>{"Replaced serial number:"}</Modal.Title>
       </Modal.Header>
       <Modal.Body>
         <Form.Group className="mb-3" controlId="sn">
           <Form.Label>Serial number:</Form.Label>
           <Form.Control 
                as="input" 
                name="sn" 
                defaultValue={serial}
                required
                ref={serialRef}
                minLength={15}
                maxLength={15}
            />
         </Form.Group>
         <div className="d-flex justify-content-end">
           <Button className="me-3" variant="secondary" onClick={onHide}>
             Cancel
           </Button>
           <Button variant="primary" type="submit">
             Submit
           </Button>
         </div>
       </Modal.Body>
     </Form>
   </Modal>
 );
}

export default SnModal;