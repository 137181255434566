import React, { useState, useRef, useContext, useEffect } from "react";
import { Card, Form, Row, Col, Stack, Button, Toast, ToastContainer, Spinner } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { RmaContext } from "../contexts/RmaContext";
import ShipAddrList from "../components/ShipAddrList";
import ProductList from "../components/ProductList";
import { XCircleFill } from "react-bootstrap-icons";

const EditRmaForm = ({rma, onClose}) => {
  const { isAllowedRole, currentUser, getUserInfo } = useAuth();
  const { editRma, products, shippingAddress, loadingShippingAddress } = useContext(RmaContext);
  const [addrValue, setAddrValue] = useState(rma.shipingaddr);
  const [userShippingAddress, setUserShippingAddress] = useState(shippingAddress);

  const fromCompanyRef = useRef("");
  const filedByRef = useRef("");
  const dateRef = useRef();
  const poRef = useRef();
  const commentRef = useRef();
  const doaRef = useRef();
  const [fields, setFields] = useState(rma.products);
  const [submitting, setSubmitting] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onKeyPressed = (e) => {
    if (e.key === 'Escape') onClose();
  }

  useEffect(()=>{
    //Componet mount
    document.addEventListener('keydown', onKeyPressed, false);

    if (currentUser.userId !== rma.userid) {

      getUserInfo(
        rma.userid,
        user => {
          let addresses = shippingAddress.filter(addr => addr.customerid === rma.userid);
          addresses.push({
            id: -1,
            customerid: rma.userid,
            address: user.address,
            city: user.city,
            zip: user.zip,
            country: user.country,
            state: user.state,
            phone: user.phone,
            name: user.company,
          });
          setUserShippingAddress(addresses);
        },
        error => {
          alert(error.message);
        }
      );

    } else {
      setUserShippingAddress( shippingAddress );
    }

    return () => {
      //Component unmount
      document.removeEventListener('keydown', onKeyPressed)
    }
  }, []);


  /** Handle Field Change *********************************************/
  const handleFieldChange = (index, event) => {
    event.preventDefault();
    event.persist();

    setFields((prev) => {
      return prev.map((item, i) => {
        if (i !== index) return item;

        let value;

        switch (event.target.value) {
          case "on":
            value = true;
            break;
          case "off":
            value = false;
            break;
          default:
            value = event.target.value;
        }

        return {
          ...item,
          [event.target.name]: value,
        };
      });
    });
  };

/** Handle remove field **********************************************/
const handleRemoveField = (e, index) => {
    e.preventDefault();

    setFields((prev) => prev.filter((item) => item !== prev[index]));
};  

/** Handle submit ***************************************************/
const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitting(true);

    if (fields && fields.length > 0 ) {
        
        const updatedRma = {
          id: rma.id,
          rmano: rma.rmano,
          userid: currentUser.userId,
          poref: poRef.current.value,
          comments: commentRef.current.value,
          date: dateRef.current.value,
          filedby: filedByRef.current.value,
          shipingaddr: parseInt(addrValue),
          fromcompany: fromCompanyRef.current.value,
          received: rma.received,
          returned: rma.returned,
          status: rma.status,
          products: fields,
        };


        editRma(
          updatedRma,
          data => {
            setSubmitting(false);
            onClose();
          },
          error => {
            setSubmitting(false);
            setErrorMessage(error);
            setShowError(true);
          }
        )

    }
    
}      

/** Handle add product *********************************************/
const handleAddProduct = (e) => {
  e.preventDefault();

  //setNoProdAdded(false);

  const inputState = {
    product: products && products.length > 0 ? products[0].name : "",
    sn: "",
    fwswver: "",
    reason: "",
    doa: false,
    replaced_sn: "",
    notes: "",
  };

  setFields((prev) => [...prev, inputState]);
};


/** Component returns*/
  return (
    <>
      <Card
        className="mx-auto shadow"
        style={{ maxWidth: "60rem", marginBottom: "60px" }}
      >
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            {isAllowedRole ? (
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="fromCompany">
                    <Form.Label>From Company</Form.Label>
                    <Form.Control
                      ref={fromCompanyRef}
                      type="text"
                      maxLength={30}
                      defaultValue={rma.fromcompany}
                    />
                  </Form.Group>
                </Col>
              </Row>
            ) : (
              <Form.Control
                className="collapse"
                ref={fromCompanyRef}
                type="text"
                maxLength={30}
                defaultValue={rma.fromcompany}
              />
            )}

            <Row>
              <Col sm={7}>
                <Form.Group className="mb-3" controlId="filedby">
                  <Form.Label>Filled by</Form.Label>
                  <Form.Control
                    ref={filedByRef}
                    type="text"
                    required
                    maxLength={30}
                    defaultValue={rma.filedby}
                  />
                </Form.Group>
              </Col>

              <Col sm={5}>
                <Form.Group className="mb-3" controlId="date">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    ref={dateRef}
                    defaultValue={rma.date} // "2022-01-27"
                    type="date"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={5}>
                <Form.Group className="mb-3" controlId="po">
                  <Form.Label>PO/Inv. Reference</Form.Label>
                  <Form.Control
                    ref={poRef}
                    type="text"
                    required
                    maxLength={30}
                    defaultValue={rma.poref}
                  />
                </Form.Group>
              </Col>

              <Col sm={7}>
                <Form.Group className="mb-3" controlId="return-address">
                  <Form.Label>Ship to Address</Form.Label>
                  {loadingShippingAddress ? (
                    <Form.Select disabled>
                      <option>Loading...</option>
                    </Form.Select>
                  ) : (
                    <ShipAddrList
                      onValChange={(value) => {
                        setAddrValue(value);
                        console.log(value);
                      }}
                      addresses={userShippingAddress}
                      selected={addrValue} /////////////////////////////////////////////////////
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3" controlId="comment">
              <Form.Label>Comments</Form.Label>
              <Form.Control
                type="text"
                ref={commentRef}
                maxLength={255}
                defaultValue={rma.comments}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="products">
              <Stack direction="horizontal" className="align-items-baseline">
                <Button
                  className="me-auto mb-2"
                  variant="secondary"
                  size="sm"
                  onClick={handleAddProduct}
                >
                  Add defective product
                </Button>
                {fields.length <= 0 && (
                  <span style={{ color: "red" }}>
                    Need to add some product.
                  </span>
                )}
              </Stack>
              <Stack
                className="p-2"
                style={{
                  border: "1px solid #CED4DA",
                  minHeight: "2.5rem",
                  borderRadius: "5px",
                }}
              >
                {fields && fields.length > 0
                  ? fields.map((item, index) => {
                      return (
                        <Stack
                          gap={1}
                          direction="horizontal"
                          key={`item-${index}`}
                          className="mb-2 p-2"
                          style={{
                            // border: "1px solid #CED4DA",
                            border: "1px solid rgb(77,91,126)",
                            borderRadius: "0.8rem",
                          }}
                        >
                          <Stack direction="vertical">
                            <Stack
                              gap={1}
                              direction="horizontal"
                              className="mb-2"
                            >
                              <ProductList
                                name="product"
                                type="text"
                                placeholder="Product"
                                selected={item.product}
                                onChange={(e) => handleFieldChange(index, e)}
                                required
                                size="sm"
                                style={{ width: "15em" }}
                                //maxLength={30}
                                products={products}
                              />

                              <Form.Control
                                name='sn'
                                type='text'
                                placeholder='Serial number'
                                value={item.sn}
                                onChange={(e) => handleFieldChange(index, e)}
                                required
                                size="sm"
                                maxLength={15}
                                minLength={10}
                                style={{ width: "9.5em" }}
                              />

                              <Form.Control
                                name="fwswver"
                                type="text"
                                placeholder="Firmware/Software Ver."
                                value={item.fwswver}
                                onChange={(e) => handleFieldChange(index, e)}
                                required
                                size="sm"
                                maxLength={20}
                              />

                              <span className="ms-auto">
                                <XCircleFill
                                  size={20}
                                  color="red"
                                  onClick={(e) => handleRemoveField(e, index)}
                                  style={{ cursor: "pointer" }}
                                />
                              </span>
                            </Stack>

                            <Stack
                              gap={1}
                              direction="horizontal"
                              className="mb-2"
                            >
                              <Form.Control
                                name="reason"
                                type="text"
                                placeholder="Reason of return"
                                value={item.reason}
                                onChange={(e) => handleFieldChange(index, e)}
                                required
                                size="sm"
                                maxLength={255}
                              />

                              <Form.Check
                                type="checkbox"
                                id="doa"
                                name="doa"
                                label="DOA"
                                ref={doaRef}
                                onChange={(e) => handleFieldChange(index, e)}
                                className="fs-6"
                              />
                            </Stack>
                          </Stack>
                        </Stack>
                      );
                    })
                  : "No product added yet"}
              </Stack>
              
              <Button
                  className="me-auto mt-2 mb-2"
                  variant="secondary"
                  size="sm"
                  onClick={handleAddProduct}
                >
                  Add defective product
              </Button>

            </Form.Group>

            <div className="d-flex justify-content-end">
              <Button variant="danger" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="success" className="ms-2" type="submit">
                {submitting && (
                  <Spinner
                    className="me-2"
                    animation="border"
                    as="span"
                    size="sm"
                    variant="white"
                  />
                )}
                Update RMA
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <ToastContainer position="middle-center">
        <Toast show={showError} bg="danger" onClose={() => setShowError(false)}>
          <Toast.Header>
            <strong className="me-auto">Error !</strong>
          </Toast.Header>
          <Toast.Body className="text-white">{errorMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default EditRmaForm;