import TreeNode from "./TreeNode";

const TreeView = (props) => {
  const { data, types, onNodeClick, ...rest } = props;

  return (
    <div {...rest}>
      {
        data && data[0] !== null
        ?
          <ul style={{listStyle: 'none', padding: 0, marginLeft: '1rem'}}>
            {
                data.map( (node,i) => {
                    return <TreeNode key={i} node={node} types={types} onClick={onNodeClick}/>
                })
            }
      </ul>
        :
            <span style={{padding: '20px'}}>NO DATA</span>
      }
      
    </div>
  );
};

export default TreeView;
