import { Card } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import './MenuCardItem.css';

export default function MenuCard({title, link, icon}) {

  const history = useHistory();
  const [hover, setHover] = useState(false);

  function handleCardClick() {
    history.push(link);
  }

  function toggleHover() {
    setHover(!hover);
  }

  return (
    <>
      <Card
        className={
          hover
            ? "shadow-lg rounded-pill"
            : "shadow-sm rounded-pill"
        }
        style={{ cursor: "pointer", background: hover && "#E6F0FF" }}
        onClick={handleCardClick}
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
      >
        <Card.Body className="p-4 ms-3">
          <Card.Title className="d-flex">
            {icon}
            <span className="ms-3 p-3">{title}</span>
          </Card.Title>
        </Card.Body>
      </Card>
    </>
  );
}
