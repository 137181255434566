import { Container, Nav, Navbar, Dropdown } from 'react-bootstrap';
import Logo from '../kirisun-americas-logo.png';
import { useAuth } from '../contexts/AuthContext';
//import { useApi } from '../contexts/ApiContext';
import { useHistory } from "react-router-dom";

//import React, { useState } from 'react';

export default function Header({ title }) {

    const { currentUser, logOut, isAllowedRole } = useAuth();
    //const { setError } = useApi();
    const history = useHistory();

    async function handleClickLogout() {
      //setError("");

      try {
        await logOut();
        history.push("/login");
      } catch {
        //setError("Failed to log out");
      }   

    }

    return (
      <div className='no-print'>
        <Navbar
          expand="lg"
          className="shadow-sm"
          style={{ background: "#E6F0FF", position: "fixed", right: 0, left: 0, top: 0, zIndex: 1030 }}
        >
          {/* bg-primary bg-opacity-10  */}
          <Container>
            <Navbar.Brand href="/">
              <img
                src={Logo}
                width="130px"
                height="30px"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
            <Navbar.Text className="fw-bold fs-6">{title}</Navbar.Text>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Dropdown className="mx-2" align="end">
                  <Dropdown.Toggle
                    className="mx-2"
                    id="dropdown-autoclose-true"
                    style={{ background: "transparent", color: "grey" }}
                  >
                    <span className="material-icons me-2" style={{alignItems: 'baseline'}}>{ isAllowedRole ? 'admin_panel_settings' : 'account_circle'}</span>
                    {currentUser.name +
                      " " +
                      currentUser.lastname +
                      " | " +
                      currentUser.company}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => {
                        history.push("/");
                      }}
                    >
                      Home
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        history.push("/profile");
                      }}
                    >
                      Profile
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={handleClickLogout}>
                      Sign Out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    );
}


