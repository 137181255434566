import React, { createContext } from "react";
import { useAuth } from "./AuthContext";
import apiRequest from '../libs/ApiRequest';

/**                                                              */
/** API Request call                                             */
/** apiRequest(endPoint, method, token, data, onSuccess, onFail) */
/**                                                              */

export const FileContext = createContext();

const FileContextProvider = (props) => {

    const { token } = useAuth();

    const formatSize = (size) => {
      if (size < 1024) return `${size} Bytes`;
      if (size >= 1024 && size < 1024 * 1024)
        return `${(size / 1024).toFixed(2)} KB`;
      if (size >= 1024 * 1024 && size < 1024 * 1024 * 1024)
        return `${(size / (1024 * 1024)).toFixed(2)} MB`;
      else return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
    };

    const getFiles = (dir, onSuccess, onFail) => {
      apiRequest(`/api/files?path=${dir}`, "GET", token, null, onSuccess, onFail);
    };

    const downloadBrochure = (fileName) => {
      fetch(`/api/download/brochures/${fileName}`, {
          method: "GET",
          headers: {
              Authorization: `kaToken ${token}`,
          },
      })
        .then((res) => res.blob())
        .then((blob) => {
          const url = URL.createObjectURL(new Blob([blob]), {
            type: "application/pdf",
          });
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${fileName}`);
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.ref);
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          error.json().then((json) => {
            console.log(json);
          });
        });
    };

    const downloadPricebook = (fileName) => {
      fetch(`/api/download/pricebook/${fileName}`, {
          method: "GET",
          headers: {
              Authorization: `kaToken ${token}`,
          },
      })
        .then((res) => res.blob())
        .then((blob) => {
          const url = URL.createObjectURL(new Blob([blob]), {
            type: "application/pdf",
          });
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${fileName}`);
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.ref);
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          error.json().then((json) => {
            console.log(json);
          });
        });
    };

    const downloadFile = (file) => {
      fetch(`/api/download?file=${file.path}`, {
          method: "GET",
          headers: {
              Authorization: `kaToken ${token}`,
          },
      })
        .then((res) => res.blob())
        .then((blob) => {
          const url = URL.createObjectURL(new Blob([blob]), {
            type: "application/pdf",
          });
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${file.name}`);
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.ref);
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          error.json().then((json) => {
            console.log(json);
          });
        });
    };

    const getPictures = async () => {
      const response = await fetch('/api/pictures', { headers: {Authorization: `kaToken ${token}`} });
      const pictureData = await response.json();

      if (!pictureData.sucess || pictureData.pictures.count < 1) return [];

      return pictureData.pictures.rows.map( picture => ({
        src: `/api/pictures/${picture.filename}`,
        width: 1,
        height: 1
      }))
    };

    const uploadPicture = async (file) => {
      if (!file) return null;

      const pictureFormData = new FormData();
      pictureFormData.append('picture', file);

      const response = await fetch(
        '/api/pictures',
        {
          method: 'POST',
          body: pictureFormData,
          headers: {
            Authorization: `kaToken ${token}`
          },
        }
      );

      return response.json();
    }

    const value = {
        formatSize,
        getFiles,
        downloadFile,
        downloadBrochure,
        downloadPricebook,
        getPictures,
        uploadPicture
    }

    return (
      <FileContext.Provider value={value}>
        {
          props.children
        }
      </FileContext.Provider>
    );
}

export default FileContextProvider;
