import { useState } from 'react';


const Pagination = ({pages, setCurrentPage, setRowsPerPage, style, count}) => {

    const numberOfPages = [];
    for (let i= 1; i<=pages; i++) {
        numberOfPages.push(i);
    }

    const [currentButton, setCurrentButton] = useState(1);

    return (
        <div className='clearfix d-flex align-items-baseline align-content-between' style={style}>
            <div className='hint-text me-auto'>Page <b>{currentButton}</b> out of <b>{numberOfPages.length}</b> pages. Count: [<b>{count}</b>]</div>

            <span className='me-2'>Items per page:</span>

            <select defaultValue="50" className="form-select form-select-sm me-2" style={{width: '70px'}} onChange={(e)=>setRowsPerPage(e.target.value)} id="pagination-itesm-per-page">
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
            </select>

            <ul className='pagination'>
                <li 
                    className={currentButton === 1 ? "page-item disabled" : "page-item"} 
                    onClick={()=>{setCurrentButton((prev) => prev === 1 ? prev : prev-1); setCurrentPage((prev) => prev === 1 ? prev : prev-1)}}
                >
                    {/* <a className="page-link" href='#'>Previous</a> */}
                    <button className='page-link'>Previous</button>
                </li>
                {
                    numberOfPages.map( (page, index) => {
                        return (
                            <li 
                                key={index} 
                                className={currentButton === page ? "page-item active" : "page-item"}
                            >
                                <button 
                                    className='page-link' 
                                    // href='#'
                                    onClick={ ()=> {setCurrentButton(page); setCurrentPage(page)}}
                                >
                                    {page}
                                </button>
                            </li>
                        )
                    } )
                }
                <li 
                    className={currentButton === numberOfPages.length ? "page-item disabled" : "page-item"}
                    onClick={()=>{setCurrentButton((next)=>next===numberOfPages.length ? next : next+1); setCurrentPage((next)=>next===numberOfPages.length ? next : next+1)}}
                >
                    <button className="page-link">
                        Next
                    </button>
                </li>
            </ul>
        </div>
    )
}

export default Pagination;