import { Container, Col, Card } from 'react-bootstrap';
import SignUpForm from '../components/SignUpForm';
import { useState, useEffect } from 'react';
import Logo from "../kirisun-americas-logo.png";
import { Link } from "react-router-dom";

function Message({ text }) {
  return (
    <>
      <Card
        className="shadow p-4"
        style={{ borderRadius: "10px", background: "#e6f0ff" }}
      >
        <Card.Body>
          <Card.Title className="mb-4 d-flex justify-content-between">
            <img
              src={Logo}
              width="130px"
              height="30px"
              className="d-inline-block align-top"
              alt="Kirisun Americas Logo"
            />
            <h2>Message</h2>
          </Card.Title>

          <Card.Text className="fs-5 text-center">{text}</Card.Text>
        </Card.Body>
      </Card>

      <div className="w-100 text-center mt-2 text-white">
        Already have an account?{" "}
        <Link to="/login" className="text-warning">
          Log In
        </Link>
        {", or "}
        <Link to="/signup" className="text-warning" onClick={() => window.location.reload()}>
          Sign Up
        </Link>
        {" to get one."}
      </div>
    </>
  );
}


export default function SignUpPage() {

    const [submitted, setSubmitted] = useState(false);
    const [message, setMessage] = useState('');

    function handleSubmit(res) {
      setMessage(res.message);
      setSubmitted(true);
    }

    useEffect(() => {
      setMessage("");
      setSubmitted(false);
    }, []);

    return (
      <Container
        fluid
        className="d-flex align-items-center justify-content-center"
        style={{ 
          minHeight: "100vh", 
          backgroundImage:'linear-gradient(135deg, rgba(51,85,119,0.4), rgba(51,85,119, 1))' 
        }} //background:'#335577' 
      >
        <Col sm={12} md={6}>
        { submitted ? <Message text={message}/> : <SignUpForm onSubmit={handleSubmit}/> }
        </Col>  

      </Container>
    );

    
}