import React, { useState, useContext } from 'react';
import { Badge } from "react-bootstrap";
import {RmaContext} from '../contexts/RmaContext';
import NoteModal from './NoteModal';
import SnModal from './SnModal';
import RepairCostModal from './RepairCostModal';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <>
    <span
      ref={ref}
      className="material-icons"
      style={{ cursor: "pointer", color: "blue" }}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      more_vert
    </span>

    {children}
  </>
));

const Product = ({ data, actions, onError }) => {

  const {rmas, setProductWarranty, setProductRepaired, setProductReplaced, setProductNotes} = useContext(RmaContext);
  const [warrant, setWarrant] = useState(data.warranty);
  const [repaired, setRepair] = useState(data.repaired);
  const [replaced, setReplace] = useState(data.replaced);
  const [replacedSn, setReplacedSn] = useState(data.replaced_sn);
  const [loading, setLoading] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [notes, setNotes] = useState(data.notes);
  const [showSnModal, setShowSnModal] = useState(false);
  const [showCostModal, setShowCostModal] = useState(false);
  const [repairedCost, setRepairedCost] = useState(data.rep_price);

  const beforeQuerying = () => {
    setLoading(true);
    window.document.body.style.cursor = "wait";
  }

  const afterQuerying = () => {
    setLoading(false);
    window.document.body.style.cursor = "initial";
  };  

  const setWarranty = () => {
    beforeQuerying();

    setProductWarranty(data.id, data.rmaId, !warrant, 
      data => {
        setWarrant(!warrant);
        afterQuerying();
      }, 
      error => {
        afterQuerying();
        onError(error)
      }
    );
  }

  const setRepaired = () => { //need to add repairing prize if applyed
    if (repaired) {
      beforeQuerying();
      setProductRepaired(
        data.id,
        data.rmaId,
        !repaired,
        0,
        (data) => {
          setRepair(!repaired);
          afterQuerying();
        },
        (error) => {
          afterQuerying();
          onError(error)
        }
      );
    } else {
      setShowCostModal(true);
    }
    
  };

  const handleCostSubmit = (e) => {
    e.preventDefault();
    const cost = e.target.cost.value;
    setShowCostModal(false);
    beforeQuerying();
    setProductRepaired(
      data.id,
      data.rmaId,
      !repaired,
      cost,
      (data) => {
        setRepair(!repaired);
        setRepairedCost(cost);
        console.log('Setting repairing cost success');
        afterQuerying();
      },
      (error) => {
        afterQuerying();
        onError(error)
      }
    );
  };

  const setReplaced = () => {
    if (replaced) {
      const serial = '';
      beforeQuerying();
      setProductReplaced(
        data.id,
        data.rmaId,
        !replaced,
        serial,
        (data) => {
          setReplace(!replaced);
          console.log("Setting replaced success");
          setReplacedSn(serial);
          afterQuerying();
        },
        (error) => {
          afterQuerying();
          onError(error)
        }
      );
    } else {
      setShowSnModal(true);
    }
    
  }
  
  const handleSnSubmit = (e) => {
    e.preventDefault();
    const serial = e.target.sn.value;
    setShowSnModal(false);

    beforeQuerying();

      setProductReplaced(
        data.id,
        data.rmaId,
        !replaced,
        serial,
        (data) => {
          setReplace(!replaced);
          console.log("Setting replaced success");
          setReplacedSn(serial);
          afterQuerying();
        },
        (error) => {
          afterQuerying();
          onError(error)
        }
      );
    

    
  };

  const addNote = () => {
    setShowNoteModal(true);
  }

  const handleNoteSubmit = (e) => {
    e.preventDefault();
    const newNotes = e.target.notes.value;
    setShowNoteModal(false);
    beforeQuerying();

    setProductNotes(
      data.id,
      data.rmaId,
      newNotes,
      (data) => {
        setNotes(newNotes);
        afterQuerying()
      },
      (error) => {
        afterQuerying();
        onError(error);
      }
    );
  }

  return (
    <>
      <tr>
        <td>{data.index}</td>
        <td>{data.product}</td>
        <td>{data.sn}</td>
        <td>{data.reason}</td>
        <td>{data.fwswver}</td>
        <td>
          {data.doa ? (
            <span className="material-icons-outlined">check_box</span>
          ) : (
            <span className="material-icons-outlined">
              check_box_outline_blank
            </span>
          )}
        </td>
        <td>
          {warrant ? (
            <span
              className="material-icons-outlined"
              style={{ cursor: !loading && actions && "pointer" }}
              onClick={actions ? setWarranty : undefined}
            >
              check_box
            </span>
          ) : (
            <span
              className="material-icons-outlined"
              style={{ cursor: !loading && actions && "pointer" }}
              onClick={actions ? setWarranty : undefined}
            >
              check_box_outline_blank
            </span>
          )}
        </td>

        <td>
          {repaired ? (
            <div style={{display:'flex', flexDirection:'row'}}>
              <span
                className="material-icons-outlined"
                style={{ cursor: !loading && actions && "pointer" }}
                onClick={actions ? setRepaired : undefined}
              >
                check_box
              </span>
              {(repairedCost > 0) && <Badge bg='warning' text='dark'>{'$ ' + repairedCost}</Badge>}
            </div>
          ) : (
            <span
              className="material-icons-outlined"
              style={{ cursor: !loading && actions && "pointer" }}
              onClick={actions ? setRepaired : undefined}
            >
              check_box_outline_blank
            </span>
          )}
        </td>

        <td>
          {replaced ? (
            <div style={{display:'flex', flexDirection:'row'}}>
              <span
                className="material-icons-outlined"
                style={{ cursor: !loading && actions && "pointer" }}
                onClick={actions ? setReplaced : undefined}
              >
                check_box
              </span>
              {(replacedSn !== '') && <Badge>{replacedSn}</Badge>}
            </div>
          ) : (
            <span
              className="material-icons-outlined"
              style={{ cursor: !loading && actions && "pointer" }}
              onClick={actions ? setReplaced : undefined}
            >
              check_box_outline_blank
            </span>
          )}
        </td>

        {actions && (
          <td className="text-center  justify-content-end">
            {/* <span className="material-icons-outlined" style={{color: 'orange', cursor: 'pointer'}} onClick={setWarranty}>verified_user</span>           */}
            {/* <span className="material-icons-outlined" style={{color: 'green', cursor: 'pointer'}} onClick={setRepaired}>handyman</span>           */}
            {/* <span className="material-icons-outlined" style={{color: 'red', cursor: 'pointer'}} onClick={setReplaced}>published_with_changes</span>           */}
            <span
              className="material-icons-outlined"
              style={{ color: "blue", cursor: "pointer" }}
              onClick={addNote}
            >
              edit_note
            </span>

            <NoteModal
              title={"Add product notes"}
              notes={notes}
              show={showNoteModal}
              onHide={() => setShowNoteModal(false)}
              onSubmit={handleNoteSubmit}
            />

            <SnModal
              serial={replacedSn}
              show={showSnModal}
              onHide={() => setShowSnModal(false)}
              onSubmit={handleSnSubmit}
            />

            <RepairCostModal
              cost={repairedCost}
              show={showCostModal}
              onHide={() => setShowCostModal(false)}
              onSubmit={handleCostSubmit}
            />
          </td>
        )}
      </tr>

      <tr>
        {actions ? <td colSpan="10">{notes}</td> : <td colSpan="9">{notes}</td>}
      </tr>
    </>
  );
};

export default Product;
