import Header from "./Header";
import Footer from './Footer';
import { Container } from 'react-bootstrap';

const Page = ({title, children}) => {
    return (
        <>
        <div style={{minHeight: 'calc(100vh - 3.5rem - 85px)', backgroundColor: '#f4f6f5'}}>

            <Header title={title}/>

            <Container style={{marginTop: "70px", backgroundColor: '#f4f6f5'}}>

                {children}

            </Container>

        </div>

        <Footer/>
        </>
    )
}

export default Page;