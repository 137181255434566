import {Card, Modal, CloseButton} from 'react-bootstrap';
import {useState, useCallback} from 'react';
// import Carousel, {Modal, ModalGateway} from 'react-images';

const PictureView = ({data, onClickDownload}) => {

  const [showImage, setShowImage] = useState(false);
  const [currentImage, setCurrentImage] = useState();
  const [showOverlay, setShowOverlay] = useState(false);

  let cardTitle = '';

  const viewImage = (src) => {
    setCurrentImage(src);
    setShowImage(true);
  }

  return (
    <>
    <div
        style={{
            display: 'grid',
            maxWidth: '1200px',
            margin: '0 auto',
            gap: '0.5rem',
            gridTemplateColumns: 'repeat(auto-fit, minmax(14rem, 1fr))'
        }}
    >
        {
            data.map( (item, index) => {
                cardTitle = (item.name).substring(0, (item.name.lastIndexOf('.')));

                return  (cardTitle !== '') && (
                    <Card key={index} className='shadow' style={{padding: '0.5rem', width: '14rem', height: '14rem'}}>

                        <div
                            className='border'
                            style={{
                                width: '100%',
                                height: '11rem',
                                backgroundImage: `url(${item.src})`,
                                backgroundSize: 'contain',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                cursor: 'pointer',
                            }}
                            onClick={()=>viewImage(item.src)}
                        />

                        <Card.Body className='d-flex flex-row justify-content-between p-0 mt-2'>
                            <div style={{fontSize: '0.75rem'}}>{cardTitle}</div>
                            <span
                              className="material-icons ms-1"
                              style={{
                                color: "blue",
                                cursor: "pointer",
                              }}
                              onClick={() => onClickDownload(item)}
                            >
                              file_download
                            </span>
                        </Card.Body>
                    </Card>
                ) 

            })
        }
    </div>

    <Modal show={showImage} centered onHide={()=>setShowImage(false)}>
        <div
          className='border'
          style={{
              height: '85vh',
              backgroundImage: `url(${currentImage})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
          }}
        />
    </Modal>


    { showOverlay &&
      <div
        style={{
          zIndex: 1099,
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0,0,0,0.5)'
        }}
      >
        <CloseButton onClick={()=>setShowOverlay(false)}/>
      </div>
    }

    
    
    </>
)

}

export default PictureView;
