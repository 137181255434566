class Chart {
    constructor(canvas, width, height, title, data) {
        this.canvas = canvas; //document.getElementById(canvas);
        this.CANVAS_WIDTH = this.canvas.width = width;
        this.CANVAS_HEIGHT = this.canvas.height = height;
        this.ctx = this.canvas.getContext('2d');
        this.title = title;
        this.data = data;

        //console.log(this.CANVAS_WIDTH, this.CANVAS_HEIGHT)
    }
    static randomColor() {
        let r = Math.floor(Math.random() * 256).toString(16);
        let g = Math.floor(Math.random() * 256).toString(16);
        let b = Math.floor(Math.random() * 256).toString(16);
        let color = `#${r}${g}${b}`;
        return color;
    }
    draw() {
        this.ctx.font = `${Math.floor(this.CANVAS_HEIGHT/15)}px Arial`;
        this.ctx.textAlign = "center";
        this.ctx.fillText(this.title, this.CANVAS_WIDTH * 0.5, this.CANVAS_HEIGHT/16+15, this.CANVAS_WIDTH);

        if (this.data.length === 0) {
            this.ctx.textAlign = 'center';
            this.ctx.fillText('NO DATA AVAILABLE', this.CANVAS_WIDTH * 0.5, this.CANVAS_HEIGHT * 0.5, this.CANVAS_WIDTH);
        }
    }
}

export default Chart;