import { Form, Modal, Button } from "react-bootstrap"
import { useRef } from 'react';

const NoteModal = ({title, notes, show, onSubmit, onHide}) => {

    const noteRef = useRef();
    
    return (
        <Modal
            show={show}
            onHide={onHide}
        >
            <Form onSubmit={onSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form.Group className="mb-3" controlId="notes">
                        <Form.Label>Note</Form.Label>
                        <Form.Control
                            name="notes"
                            ref={noteRef}
                            defaultValue={notes}
                            as="textarea"
                            rows={3}
                            required
                        />
                    </Form.Group>

                    <div className="d-flex justify-content-end">
                        <Button className='me-3' variant="secondary" onClick={onHide}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Add Note
                        </Button>
                    </div>
                </Modal.Body>
            </Form>

            
        </Modal>
    )
}

export default NoteModal;