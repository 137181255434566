import { Container, Row, Col  } from 'react-bootstrap';
import LoginForm from '../components/LoginForm';


export default function LoginPage() {
    return (
      <Container
        fluid
        // className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "100vh",
          backgroundImage:
            "linear-gradient(135deg, rgba(51,85,119,0.4), rgba(51,85,119,1))",
        }}
      >
        <Row>
          <Col
            style={{
              padding: "2rem",
              textAlign: "center",
              fontSize: "32px",
              fontWeight: "900",
              color: "#FFFFFF",
              // color: "#8E44AD",
              // textShadow: "2px 2px #EEEEEE",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            WELCOME TO KIRISUN AMERICAS DEALER ZONE
          </Col>
        </Row>

        <Row>
          <Col md={5}>
            <LoginForm
              style={{
                maxWidth: "400px",
                margin: "auto",
                marginBottom: "1.5rem",
              }}
            />
          </Col>

          <Col
            md={7}
            style={{
              color: "white",
              fontSize: "18px",
            }}
          >
            <h5>
              <u>LOGIN TERMS AND CONDITIONS</u>
            </h5>
            <p>
              By accessing <strong>Kirisun Americas</strong> Dealer Zone Login
              Section, you agree to comply with the terms and conditions listed
              below:
            </p>
            <ul>
              <li>
                You are an approved Kirisun Americas Manufacturing
                Representative, Distributor, Authorized Warranty Station or
                Dealer
              </li>
              <li>
                You agree that the documents and software contained withing the
                Dealer Zone site are confidential material and are for your own
                use only
              </li>
              <li>
                You also understand and agree that your username will be logged
                as a record of your download
              </li>
              <li>
                If you do not agree with the above terms and conditions, please
                EXIT this page
              </li>
            </ul>

            <h5>
              <u>IMPORTANT NOTICE</u>
            </h5>
            <p>
              Kirisun Americas Authorized Dealers please register <a href="/signup" style={{ color: "orange" }}>here</a>
            </p>
            <p>
              If you have any questions, please contact Kirisun Americas Sales Department or
              Technical Support Department at:
            </p>  
              <ul style={{listStyle:'none'}}>
                <li>
                  Telephone: {' '}
                  <a style={{ color: "orange" }} href="tel:+17863594933">
                    +1 (786) 359-4933
                  </a>
                </li>
                <li>
                  or by email to{" "}
                  <a
                    style={{ color: "orange" }}
                    href="mailto:support@kirisunamericas.com?subject=Dealer Zone Account Request"
                  >
                    support@kirisunamericas.com
                  </a>
                </li>
              </ul>
            <p>For other regions worldwide please contact:</p>
            <ul>
              <li>
                Kirisun Communication Ltd - email:{" "}
                <a
                  style={{ color: "orange" }}
                  href="mailto:marketing.os@kirisun.com"
                >
                  marketing.os@kirisun.com
                </a>
              </li>
              {/* <li>
                Kirisun United Kindom - email:{" "}
                <a style={{ color: "orange" }} href="mailto:uk@kirisun.com">
                  uk@kirisun.com
                </a>
              </li>
              <li>
                Kirisun Europe - email:{" "}
                <a style={{ color: "orange" }} href="mailto:europe@kirisun.com">
                  europe@kirisun.com
                </a>
              </li>
              <li>
                Kirisun Australasia - email:{" "}
                <a
                  style={{ color: "orange" }}
                  href="mailto:australasia@kirisun.com"
                >
                  australasia@kirisun.com
                </a>
              </li>
              <li>
                Kirisun Philippines - email:{" "}
                <a
                  style={{ color: "orange" }}
                  href="mailto:philippines@kirisun.com"
                >
                  philippines@kirisun.com
                </a>
              </li> */}
            </ul>
          </Col>
        </Row>
      </Container>
    );
}