import React, {useContext, useEffect, useState}  from 'react';
import { Card, Table, Button, Form } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import Rma from "./Rma";
import { RmaContext } from '../contexts/RmaContext';
import Pagination from './Pagination';
import DateModal from "./DateModal";
import ToastMessage from "./ToastMessage";
import ConfirmationDialog from "./ConfirmationDialog";  
import RmaMenu from './RmaMenu';

const RmaList = ({onAddButtonClick, onView, onEdit}) => {
  const { isAllowedRole } = useAuth();

  const { rmas, rmaYears, delRma, patchRma, loading, selectedYear, setSelectedYear, search, setSearch, currentPage, setCurrentPage } = useContext(RmaContext);
  //const [currentPage, setCurrentPage] = useState(1);
  const [rmasPerPage, setRmasPerPage] = useState(50);
  const [sortedField, setSortedField] = useState("date");
  //const [search, setSearch] = useState("");
  const [dateModalTitle, setDateModalTitle] = useState("");
  const [dateModalShow, setDateModalShow] = useState(false);
  const [dateSubmitHandler, setDateSubmitHandler] = useState(null);
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [toastMessage, setToastMessage] = useState("Hello World!");
  const [toastMessageBg, setToastMessageBg] = useState("info");
  const [delText, setDeltext] = useState("");
  const [delShow, setDelShow] = useState(false);
  const [rmaToDel, setRmaToDel] = useState({});
  const [mousePos, setMousePos] = useState({x:0, y:0});
  const [showPopupMenu, setShowPopupMenu] = useState(false);
  const [selectedRma, setSelectedRma] = useState(null);
  const [selectedRow, setSelectedRow] = useState();

  
  const indexOfLastRma = currentPage * rmasPerPage;
  const indexOfFirstRma = indexOfLastRma - rmasPerPage;
  var totalPages = Math.ceil(rmas.length / rmasPerPage); //recalculate here when rma is filtered by search box

  //Message Toast

  const MessageToast = (msg, bg) => {
    setToastMessage(msg);
    setToastMessageBg(bg);
    setShowToastMessage(true);
  };

  
  /** When Menu icon is pressed */ 

  const handleMenu = (e, data) => {
    e.preventDefault();
    setMousePos({x:e.pageX, y:e.pageY});
    setSelectedRma(data);
    //setPrevBackColor(selectedRow.style.backgroundColor);
    //selectedRow.style.backgroundColor = '#E5EFFE';
    setShowPopupMenu(true);
  }   

  /** Search */

  const handleSearch = (e) => {
    setSearch(e.target.value.toUpperCase());
  };

  /** Delete */
  const handleDelete = (rma) => {
    setRmaToDel(rma);
    setDeltext(`Are you sure you want to delete RMA: ${rma.rmano} ?`);
    setDelShow(true);
  };

  const handleDelConfirmation = (data) => {
    setDelShow(false);

    delRma(
      data.id,
      () => {
        MessageToast("Rma - Deleted succesfully", "success");
      },
      (error) => {
        MessageToast(
          `Rma - Error deleting rma. ${error.message}`,
          "danger"
        );
      }
    );
  };

  /** Set received */
  const handleSetReceived = (e) => {
    setShowPopupMenu(false);

    if (selectedRma) {
      setDateSubmitHandler(() => dateReceivedSubmitted);
      setDateModalTitle("Enter RMA received date");
      setDateModalShow(true);
    }

  };

  const dateReceivedSubmitted = (e) => {
    e.preventDefault();
    const date = e.target.date.value;
    setDateModalShow(false);

    patchRma(
      selectedRma,
      {
        received: `${date}`,
        status: "received",
      },
      (resData) => {
        MessageToast("Rma - Date received updated", "success");
      },
      (error) => {
        MessageToast(
          `Rma - Error updating received date. ${error.message}`,
          "danger"
        );
      }
    );
  };

  /** Set Processing */
  const handleSetProcessing = (e) => {
    setShowPopupMenu(false);
    if (selectedRma) {
      setDateSubmitHandler(() => dateProcessingSubmitted);
      setDateModalTitle("Enter RMA start processing date");
      setDateModalShow(true);
    }
  };

  const dateProcessingSubmitted = (e) => {
    e.preventDefault();
    const date = e.target.date.value;
    setDateModalShow(false);

    patchRma(
      selectedRma,
      {
        processing: `${date}`,
        status: "progress",
      },
      (resData) => {
        MessageToast("Rma - Date start processing updated", "success");
      },
      (error) => {
        MessageToast(
          `Rma - Error updating processing date. ${error.message}`,
          "danger"
        );
      }
    );
  };

  /** Set Waiting for reply */
  const handleSetWaitingForReply = (e) => {
    setShowPopupMenu(false);
    if (selectedRma) {
      setDateSubmitHandler(() => dateWaitForReplySubmitted);
      setDateModalTitle("Enter RMA waiting for reply date");
      setDateModalShow(true);
    }
  };

  const dateWaitForReplySubmitted = (e) => {
    e.preventDefault();
    const date = e.target.date.value;
    setDateModalShow(false);

    patchRma(
      selectedRma,
      {
        waiting: `${date}`,
        status: "waiting",
      },
      (resData) => {
        MessageToast("Rma - Date waiting for reply updated", "success");
      },
      (error) => {
        MessageToast(
          `Rma - Error updating waiting for reply date. ${error.message}`,
          "danger"
        );
      }
    );
  };

  /** Set Waiting for delivery */

  const handleSetWaitForDelivery = (e) => {
    setShowPopupMenu(false);
    if (selectedRma) {
      setDateSubmitHandler(() => dateWaitForDeliverySubmitted);
      setDateModalTitle("Enter RMA waiting for delivery date");
      setDateModalShow(true);
    }
    
  };

  const dateWaitForDeliverySubmitted = (e) => {
    e.preventDefault();
    const date = e.target.date.value;
    setDateModalShow(false);

    patchRma(
      selectedRma,
      {
        todeliver: `${date}`,
        status: "pending",
      },
      (resData) => {
        MessageToast("Rma - Date waiting for delivery updated", "success");
      },
      (error) => {
        MessageToast(
          `Rma - Error updating waiting for delivery date. ${error.message}`,
          "danger"
        );
      }
    );
  };

  /** Set Returned */

  const handleSetReturned = (e) => {
    setShowPopupMenu(false);
    if (selectedRma) {
      setDateSubmitHandler(() => dateReturnedSubmitted);
      setDateModalTitle("Enter RMA returned date");
      setDateModalShow(true);
    }
  };

  const dateReturnedSubmitted = (e) => {
    e.preventDefault();
    const date = e.target.date.value;
    setDateModalShow(false);

    patchRma(
      selectedRma,
      {
        returned: `${date}`,
        status: "returned",
      },
      (resData) => {
        MessageToast("Rma - Date returned updated", "success");
      },
      (error) => {
        MessageToast(
          `Rma - Error updating returned date. ${error.message}`,
          "danger"
        );
      }
    );
  };

  
  // Popup Menu Items

  const menuItems = [
    {
      header: true,
      title: "Set State",
      onClick: null,
    },
    {
      header: false,
      title: "Set Received",
      icon: "call_received",
      onClick: handleSetReceived
    },
    {
      header: false,
      title: "Set Processing",
      icon: 'precision_manufacturing',
      onClick: handleSetProcessing,
    },
    {
      header: false,
      title: "Set Waiting for Reply",
      icon: 'hourglass_top',
      onClick: handleSetWaitingForReply,
    },
    {
      header: false,
      title: "Set Waiting for Delivery",
      icon: 'local_shipping',
      onClick: handleSetWaitForDelivery,
    },
    {
      header: false,
      title: "Set Returned",
      icon: 'u_turn_left',
      onClick: handleSetReturned,
    },
    {
      header: true,
      title: "Override",
      onClick: null
    },
    {
      header: false,
      title: "Delete",
      onClick: null
    }
  ];

  useEffect( () => {

    const handleMouseOver = (e) => {
      //console.log(e.target.tagName);
      if ( (e.target.tagName === 'TD') && (e.target.parentElement.tagName === 'TR') ) {
        //console.log('Hovering over table row');
        setSelectedRow(e.target.parentElement);
      }
    }

    document.addEventListener('mouseover', handleMouseOver, true);

    return () => {
      document.removeEventListener('mouseover', handleMouseOver, true);
    }
  }, []);

  return (
    <>
      <Card
        className="my-3 shadow"
        style={{ height: "calc(100vh - 130px)", fontSize: "14px" }}
      >
        <Card.Header
          className="d-flex align-items-baseline"
          style={{ backgroundColor: "#4d5b7e" }}
        >
          <h5 className="text-white">RMA List</h5>

          <Form.Control
            id = "rma-list-search"
            name = "search"
            className="ms-auto"
            type="search"
            placeholder="Search"
            style={{ width: "30%" }}
            onChange={handleSearch}
            value={search}
          />

          <Form.Select
            id = "rma-list-year-select"
            name = "year-select"
            className="ms-2"
            style={{ width: "120px" }}
            onChange={
              (e) => {
                setSelectedYear(e.target.value);
              }
            }
            value={selectedYear}
          >
            {rmaYears.map((year, index) => (
              <option key={index} value={year}>
                {year}
              </option>
            ))}
          </Form.Select>

          <Button
            variant="outline-light"
            className="ms-2"
            onClick={onAddButtonClick}
          >
            New RMA
          </Button>
        </Card.Header>

        <Card.Body style={{ overflow: "auto" }}>
          <Table striped responsive className="border"> 
            <thead>
              <tr>
                <th>#</th>
                {isAllowedRole && (
                  <th
                    onClick={() => setSortedField("fromcompany")}
                    style={{ cursor: "pointer" }}
                  >
                    From Company
                    {sortedField === "fromcompany" && (
                      <span
                        className="material-icons"
                        style={{ fontSize: "20px" }}
                      >
                        arrow_drop_down
                      </span>
                    )}
                  </th>
                )}
                <th
                  onClick={() => setSortedField("rmano")}
                  style={{ cursor: "pointer" }}
                >
                  RMA No.
                  {sortedField === "rmano" && (
                    <span
                      className="material-icons"
                      style={{ fontSize: "20px" }}
                    >
                      arrow_drop_down
                    </span>
                  )}
                </th>

                <th
                  onClick={() => setSortedField("poref")}
                  style={{ cursor: "pointer" }}
                >
                  PO/INV Ref.
                  {sortedField === "poref" && (
                    <span
                      className="material-icons"
                      style={{ fontSize: "20px" }}
                    >
                      arrow_drop_down
                    </span>
                  )}
                </th>

                <th
                  onClick={() => setSortedField("filedby")}
                  style={{ cursor: "pointer" }}
                >
                  Filled By
                  {sortedField === "filedby" && (
                    <span
                      className="material-icons"
                      style={{ fontSize: "20px" }}
                    >
                      arrow_drop_down
                    </span>
                  )}
                </th>

                <th
                  onClick={() => setSortedField("date")}
                  style={{ cursor: "pointer" }}
                >
                  Issued
                  {sortedField === "date" && (
                    <span
                      className="material-icons"
                      style={{ fontSize: "20px" }}
                    >
                      arrow_drop_down
                    </span>
                  )}
                </th>

                <th
                  onClick={() => setSortedField("received")}
                  style={{ cursor: "pointer" }}
                >
                  Received
                  {sortedField === "received" && (
                    <span
                      className="material-icons"
                      style={{ fontSize: "20px" }}
                    >
                      arrow_drop_down
                    </span>
                  )}
                </th>

                <th
                  onClick={() => setSortedField("returned")}
                  style={{ cursor: "pointer" }}
                >
                  Returned
                  {sortedField === "returned" && (
                    <span
                      className="material-icons"
                      style={{ fontSize: "20px" }}
                    >
                      arrow_drop_down
                    </span>
                  )}
                </th>

                <th
                  onClick={() => setSortedField("status")}
                  style={{ cursor: "pointer" }}
                >
                  Status
                  {sortedField === "status" && (
                    <span
                      className="material-icons"
                      style={{ fontSize: "20px" }}
                    >
                      arrow_drop_down
                    </span>
                  )}
                </th>

                <th className="text-end" style={{ width: "8rem" }}>
                  Actions
                </th>
              </tr>
            </thead>

            <tbody>

              {/* ---------- List of RMAs ------------------------------------------------------------------------------------------------ */}

              {/* {currentRmas && currentRmas.length > 0 ? (
                currentRmas */}
              {rmas && rmas.length > 0 ? (
                rmas  
                  .filter((e) => {
                      return `${e.fromcompany.toUpperCase()} ${e.poref.toUpperCase()} ${e.rmano.toUpperCase()} ${e.filedby.toUpperCase()} 
                      ${e.date} ${e.received} ${e.returned} ${e.status.toUpperCase()}`.includes(search);
                    }
                  )
                  .sort((a, b) => {
                    if (sortedField === 'received') return (a[sortedField] < b[sortedField] ? 1 : -1); 
                    if (sortedField === 'returned') return (a[sortedField] < b[sortedField] ? 1 : -1); 
                    if (sortedField === 'date') return (a[sortedField] < b[sortedField] ? 1 : -1); 
                    else { return( a[sortedField] < b[sortedField] ? -1 : 1) }
                  })
                  //.slice(indexOfFirstRma, indexOfLastRma) 
                  .map((rma, index, arr) => {
                    totalPages = Math.ceil(arr.length / rmasPerPage);
                    index++;
                    return (
                      <Rma
                        key={index}
                        index={index}
                        data={rma}
                        onView={onView}
                        onEdit={onEdit}
                        onDelete={handleDelete}
                        //onSetReceived={handleSetReceived}
                        //onSetProcessing={handleSetProcessing}
                        //onSetWaitingForReply={handleSetWaitingForReply}
                        //onSetWaitForDelivery={handleSetWaitForDelivery}
                        //onSetReturned={handleSetReturned}
                        onMenu={handleMenu}
                      />
                    );
                  })
                  .slice(indexOfFirstRma, indexOfLastRma) 
              ) : (
                <tr>
                  {isAllowedRole ? (
                    <td className="p-5 text-center" colSpan="11">
                      {loading ? "Loading..." : "No Data"}
                    </td>
                  ) : (
                    <td className="p-5 text-center" colSpan="10">
                      {loading ? "Loading..." : "No Data"}
                    </td>
                  )}
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>

        <Card.Footer>
          <Pagination
            pages={totalPages}
            setCurrentPage={setCurrentPage}
            setRowsPerPage={setRmasPerPage}
            // count={currentRmas.length}
            count={rmas.length}
          />
        </Card.Footer>
      </Card>

      <DateModal
        title={dateModalTitle}
        show={dateModalShow}
        onHide={() => setDateModalShow(false)}
        onSubmit={dateSubmitHandler}
      />

      <ToastMessage
        show={showToastMessage}
        message={toastMessage}
        background={toastMessageBg}
        onClose={() => setShowToastMessage(false)}
      />

      <ConfirmationDialog
        data={rmaToDel}
        text={delText}
        show={delShow}
        onHide={() => setDelShow(false)}
        onYes={handleDelConfirmation}
      />

      <RmaMenu 
        menuItems={menuItems} 
        show={showPopupMenu} 
        position={mousePos}
        onClickOutside={ () => {setShowPopupMenu(false)} }
      />

    </>
  );
};

export default RmaList;
