// import DownloadPage from "../components/DownloadPage";

// const AppNotesPage = () => {
//     return (
//         <DownloadPage title='Application Notes' queryUrl='/api/files/app_notes'/>
//     )
// }

// export default AppNotesPage;

import FileContextProvider from "../contexts/FileContext";
import Page from "../components/Page";
import FileManager from "../components/FileManager";

const AppNotesPage = () => {

  return (
    <Page title="Application Notes">
      <FileContextProvider>
        <FileManager dir={"App_notes"} view='file'/>
      </FileContextProvider>
    </Page>
  )
  
};

export default AppNotesPage;