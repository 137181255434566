import React from 'react';
import { Dropdown } from "react-bootstrap";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <>
    <span
      ref={ref}
      className="material-icons"
      style={{ cursor: "pointer", color: "darkblue", zIndex: 10 }}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      more_vert
    </span>

    {children}
  </>
));

/**
 * 
 * menuItems will have the following format, array of objects
 * 
 * {
 *    header: false,  
 *    title: 'Item 1',
 *    onClick: handleOnClick()    
 * } 
 * 
 */
const PopUpMenu = ({menuItems}) => {
    return (
      <Dropdown className="d-inline-block" style={{ zIndex: 1050 }}>
        
        <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
        
        <Dropdown.Menu drop="start" style={{ zIndex: 1050}}>

          <Dropdown.Item>Item 1</Dropdown.Item>
          <Dropdown.Item>Item 2</Dropdown.Item>

          {menuItems.map((menuItem, index) => {
            return menuItem.header ? (
              <Dropdown.Header key={index}>{menuItem.title}</Dropdown.Header>
            ) : (
              <Dropdown.Item
                key={index}
                eventKey={`${index}`}
                onClick={menuItem.onClick}
              >
                {menuItem.title}
              </Dropdown.Item>
            );
          })}

        </Dropdown.Menu>
      
      </Dropdown>

      
    );
}

export default PopUpMenu;