import { Modal, Form, Row, Col, Button, Stack } from 'react-bootstrap';
import { useRef, useState } from 'react';

const UserModal = ( {show, title, edit, data, onCancel, onSubmit} ) => {

    const firstNameRef = useRef();
    const lastNameRef = useRef(); 
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmationRef = useRef();
    const companyNameRef = useRef();
    const addressRef = useRef();
    const cityRef = useRef();
    const stateRef = useRef();
    const zipRef = useRef();
    const countryRef = useRef();
    const phoneRef = useRef();
    const [error, setError] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        const password = edit ? data.password : passwordRef.current.value;
        const passwordConfirm = edit ? data.password : passwordConfirmationRef.current.value;
        const userId = data.id;
        const userRole = data.role;
        const userEnabled = data.enabled

        if (password === passwordConfirm) {
          let data = {
            id: userId,
            username: firstNameRef.current.value,
            userlastname: lastNameRef.current.value,
            //password: password,
            email: emailRef.current.value,
            company: companyNameRef.current.value,
            address: addressRef.current.value,
            city: cityRef.current.value,
            state: stateRef.current.value,
            zip: zipRef.current.value,
            country: countryRef.current.value,
            phone: phoneRef.current.value,
            role: edit ? userRole : 'user',
            enabled: edit ? userEnabled : false
          }
          if (!edit) {
            data = {...data, password: password}
          }
          onSubmit(data);
        } else {
          setError(true)
        }

        
    }

    return (
      <Modal show={show} onHide={onCancel}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header
            closeButton
            className="text-white"
            style={{ backgroundColor: "#4D5B7E" }}
          >
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="firstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={30}
                    ref={firstNameRef}
                    defaultValue={edit ? data.username : ''}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="lastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={30}
                    ref={lastNameRef}
                    defaultValue={edit ? data.userlastname : ''}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  maxLength={255}
                  required
                  ref={emailRef}
                  defaultValue={edit ? data.email : ''}
                />
              </Form.Group>
            </Row>
            {!edit && (
              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      required
                      ref={passwordRef}
                      autoComplete="new-password"
                      maxLength={30}
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3" controlId="passwordConfirmation">
                    <Form.Label>Password Confirmation</Form.Label>
                    <Form.Control
                      type="password"
                      required
                      ref={passwordConfirmationRef}
                      autoComplete="new-password"
                      maxLength={30}
                    />
                  </Form.Group>
                </Col>
                {error && (
                  <span className="text-danger">
                    Error: Passwords must match !
                  </span>
                )}
              </Row>
            )}
            <hr></hr>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="company">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={30}
                    ref={companyNameRef}
                    defaultValue={edit ? data.company : ''}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="address">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={30}
                    ref={addressRef}
                    defaultValue={edit ? data.address : ''}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3" controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={30}
                    ref={cityRef}
                    defaultValue={edit ? data.city : ''}
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group className="mb-3" controlId="state">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={30}
                    ref={stateRef}
                    defaultValue={edit ? data.state : ''}
                  />
                </Form.Group>
              </Col>

              <Col md={4}>
                <Form.Group className="mb-3" controlId="zip">
                  <Form.Label>Zip</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={10}
                    ref={zipRef}
                    defaultValue={edit ? data.zip : ''}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="country">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={30}
                    ref={countryRef}
                    defaultValue={edit ? data.country : ''}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="phone">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={16}
                    ref={phoneRef}
                    defaultValue={edit ? data.phone : ''}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Stack>
              <Button
                className="mb-2"
                variant="secondary"
                type="reset"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button className="mb-2" variant="primary" type="submit">
                OK
              </Button>
            </Stack>
          </Modal.Body>
        </Form>
      </Modal>
    );
}

export default UserModal;