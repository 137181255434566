import IconState from "./IconState";
//import PopUpMenu from "./PopUpMenu";
//import DateModal from "./DateModal";
//import ToastMessage from "./ToastMessage";
//import ConfirmationDialog from "./ConfirmationDialog";
//import React, { useState, useContext } from 'react';
//import { useApi } from "../contexts/ApiContext";
//import { RmaContext } from "../contexts/RmaContext";
import { useAuth } from "../contexts/AuthContext";
//import { useHistory } from 'react-router-dom';
import './Rma.css';

const Rma = ({ 
    index, 
    data, 
    onView, 
    onEdit, 
    onDelete, 
    //onSetReceived, 
    //onSetProcessing,
    //onSetWaitingForReply,
    //onSetWaitForDelivery,
    //onSetReturned,
    onMenu
  }) => {

    const { isAllowedRole } = useAuth();
    
    // //Set Received date

    // const setReceived = () => {
    //   //Handle click on Set Received popup menu Item
    //   onSetReceived(data);
    // };

    // //Set Processing date

    // const setProcessing = () => {
    //     onSetProcessing(data);
    // }

    // //Set waiting for replay date

    // const setWaitForReplay = () => {
    //     onSetWaitingForReply(data);
    // }

    // //Set waiting for delivery date

    // const setWaitForDelivery = () => {
    //     onSetWaitForDelivery(data)
    // }

    // //Set returned date

    // const setReturned = () => {
    //     onSetReturned(data)
    // }


    //Handle when view icon is clicked
    const handleViewClick = () => {
        onView(data);
    }

    //Handle when edit icon is clicked
    const handleEditClick = () => {
        onEdit(data);
    }

    //Handle when delete icon is clicked

    const handleDeleteClick = () => {
        onDelete(data);
    }

    //Handle when menu icon is clicked
    const handleMenuClick = (e) => {
      onMenu(e, data);
    }
    
  return (

      <tr className='hoverable'>
        <td>{index}</td>
        {isAllowedRole && <td>{data.fromcompany}</td>}
        <td>{data.rmano}</td>
        <td>{data.poref}</td>
        <td>{data.filedby}</td>
        <td>{data.date}</td>
        <td>{data.received}</td>
        <td>{data.returned}</td>
        <td>
          <IconState state={data.status} />
        </td>
        <td className="text-end  justify-content-end" style={{ color: "blue" }}>
          <span
            onClick={handleViewClick}
            className="material-icons"
            style={{
              cursor: "pointer",
              color: "blue",
            }}
          >
            visibility
          </span>

          <span
            onClick={handleEditClick}
            className="material-icons"
            style={{
              cursor: "pointer",
              pointerEvents:
                data.status === "sent" || isAllowedRole ? "auto" : "none",
              color: data.status === "sent" || isAllowedRole ? "orange" : "gray",
            }}
          >
            edit
          </span>

          <span
            onClick={handleDeleteClick}
            className="material-icons"
            style={{
              cursor: "pointer",
              pointerEvents: data.status === "sent" ? "auto" : "none",
              color: !(data.status === "sent") ? "gray" : "red",
            }}
          >
            delete
          </span>

          {/* {isAllowedRole && <PopUpMenu menuItems={popUpMenuItems} />} */}

          {isAllowedRole &&  
            <span
              className="material-icons"
              style={{ cursor: "pointer", color: "darkblue"}}
              onClick= {handleMenuClick}
            >
              more_vert
            </span>
          }
        </td>

        
      </tr>

  );
};

export default Rma;
