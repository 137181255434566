import {OverlayTrigger, Tooltip} from 'react-bootstrap';

const IconState = ({state, showLabel}) => {
        let icon, color, label;

        switch(state) {
            case 'sent': icon = 'send'; color = 'blue'; label='sent'; break;
            case 'received': icon = 'call_received'; color = 'red'; label='received'; break;
            case 'progress': icon = 'precision_manufacturing'; color = 'brown'; label='working on this'; break;
            case 'waiting': icon = 'hourglass_top'; color = 'orange'; label = 'waiting for customer\'s reply'; break;
            case 'pending': icon = 'local_shipping'; color = 'teal'; label = 'waiting for delivery'; break;
            case 'returned': icon = 'u_turn_left'; color = 'green'; label='returned to customer'; break;
            default: icon = 'send'; color = 'black'; label = 'Default';
        }
    
        return (
          <>
            <OverlayTrigger
                placement='bottom'
                overlay={
                    <Tooltip>{label}</Tooltip>
                }
            >
                <span className='material-icons' style={{color: `${color}`, cursor: 'pointer'}}>{icon}</span>
            </OverlayTrigger>

            { showLabel && (<span className='ms-2'>{label}</span>) }
          </>
        ) 
                
}

export default IconState;