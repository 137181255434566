import React, { useRef, useState } from 'react';
import { Card, Form, Button, Alert, Row, Col } from 'react-bootstrap';
import Logo from '../kirisun-americas-logo.png';
import { useAuth } from '../contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';

export default function SignUpForm( {onSubmit} ) {

    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const companyNameRef = useRef();
    const addressRef = useRef();
    const cityRef = useRef();
    const stateRef = useRef();
    const zipRef = useRef();
    const countryRef = useRef();
    const phoneRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmationRef = useRef();
    const { signUp } = useAuth();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        if (passwordRef.current.value !== passwordConfirmationRef.current.value) {
            return setError('Passwords do not match.');
        }

        try {
            setError('');
            setLoading(true);
            const res = await signUp({
              username: firstNameRef.current.value,
              userlastname: lastNameRef.current.value,
              email: emailRef.current.value,
              password: passwordRef.current.value,
              company: companyNameRef.current.value,
              address: addressRef.current.value,
              city: cityRef.current.value,
              state: stateRef.current.value,
              zip: zipRef.current.value,
              country: countryRef.current.value,
              phone: phoneRef.current.value,
            });

            if (res) onSubmit(res);
              
            //history.push('/login');

        } catch (e) {
            setError('Failed to create an account.');
            onSubmit({code: 500, message: e.message} );
        }    
        setLoading(false);
    }

    return (
      <>
        <Card
          //className="bg-primary bg-opacity-10 shadow p-4"
          className="shadow p-4"
          style={{ borderRadius: "10px", background: "#e6f0ff" }}
        >
          <Card.Body>
            <Card.Title className="mb-4 d-flex justify-content-between">
              <img
                src={Logo}
                width="130px"
                height="30px"
                className="d-inline-block align-top"
                alt="Kirisun Americas Logo"
              />
              <h2>Sign Up</h2>
            </Card.Title>

            {error && <Alert variant="danger">{error}</Alert>}

            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={6} style={{ borderRight: "1px solid grey" }}>
                  {/* <Stack
                    direction="horizontal"
                    className="justify-content-between"
                    gap="3"
                  > */}
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="firstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          required
                          maxLength={30}
                          ref={firstNameRef}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="lastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          required
                          maxLength={30}
                          ref={lastNameRef}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* </Stack> */}

                  <Form.Group className="mb-3" controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      maxLength={255}
                      required
                      ref={emailRef}
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      required
                      ref={passwordRef}
                      autoComplete="new-password"
                      maxLength={30}
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-4"
                    controlId="password-confirmation"
                  >
                    <Form.Label>Password Confirmation</Form.Label>
                    <Form.Control
                      type="password"
                      required
                      ref={passwordConfirmationRef}
                      autoComplete="new-password"
                      maxLength={30}
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className="mb-3" controlId="company">
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      maxLength={30}
                      ref={companyNameRef}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="address">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      required
                      maxLength={30}
                      ref={addressRef}
                    />
                  </Form.Group>

                  {/* <Stack direction='horizontal' className='justify-content-between' gap='3'> */}
                  <Row>
                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="city">
                        <Form.Label>City</Form.Label>
                        <Form.Control type="text" required maxLength={30} ref={cityRef} />
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="state">
                        <Form.Label>State</Form.Label>
                        <Form.Control type="text" required maxLength={30} ref={stateRef} />
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group className="mb-3" controlId="zip">
                        <Form.Label>Zip</Form.Label>
                        <Form.Control type="text" required maxLength={10} ref={zipRef} />
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* </Stack> */}

                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="country">
                        <Form.Label>Country</Form.Label>
                        <Form.Control type="text" required maxLength={30} ref={countryRef} />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="phone">
                        <Form.Label>Phone</Form.Label>
                        <Form.Control type="text" required maxLength={16} ref={phoneRef} />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Button
                  disabled={loading}
                  className="w-100 mt-2"
                  variant="primary"
                  type="submit"
                >
                  Sign Up Request
                </Button>
              </Row>
            </Form>
          </Card.Body>
        </Card>
        <div className="w-100 text-center mt-2 text-white">
          Already have an account?{" "}
          <Link to="/login" className="text-warning">
            Log In
          </Link>
        </div>
      </>
    );
}