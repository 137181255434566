import Page from '../components/Page';
import MenuCard from "../components/MenuCard";
import { Container } from 'react-bootstrap';

const HomePage = () => {
    return (
        <Page title="Home">
          <Container className="d-flex flex-column align-items-center justify-content-center p-5">
            <MenuCard className="mt-3" />
          </Container>
        </Page>
    );
}

export default HomePage;