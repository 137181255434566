import { Toast, ToastContainer } from "react-bootstrap";
import { useState } from 'react';

const ToastMessage = ({message, show, background, onClose}) => {

    // background = info, success, danger

  return (
    <ToastContainer className="p-3 position-fixed" position="top-center" style={{zIndex:2030}}>
      <Toast
        show={show}
        bg={background}
        delay={2000}
        autohide
        onClose={onClose}
      >
        <Toast.Body className="text-white text-center">
          {message}
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};

export default ToastMessage;
