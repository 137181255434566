import Page from "../components/Page";
import {
  Card,
  Form,
  Row,
  Button,
  Stack,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import PasswordModal from "../components/PasswordModal";
import ShippingAddressList from "../components/ShippingAddressList";

const ProfilePage = () => {
  const [user, setUser] = useState({});
  const [disabled, setDisabled] = useState(true);
  const [buttonText, setButtonText] = useState("Edit Profile");
  const { token } = useAuth();
  const history = useHistory();

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const companyNameRef = useRef();
  const addressRef = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const zipRef = useRef();
  const countryRef = useRef();
  const phoneRef = useRef();
  const emailRef = useRef();
  //const passwordRef = useRef();
  //const passwordConfirmationRef = useRef();

  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [toastBg, setToastBg] = useState("info");
  const [toastMessage, setToastMessage] = useState("Hello world");
  const [showToast, setShowToast] = useState(false);

  const [addresses, setAddresses] = useState([]);

  const pwdSuccessMsg = () => {
    setToastBg("success");
    setToastMessage("Password set succesfully.");
    setShowToast(true);
  };

  const pwdErrorMsg = () => {
    setToastBg("danger");
    setToastMessage("Error setting password.");
    setShowToast(true);
  };

  const updProfileSuccessMsg = () => {
    setToastBg("success");
    setToastMessage("Profile updated succesfully.");
    setShowToast(true);
  }

  const updProfileErrorMsg = () => {
    setToastBg("danger");
    setToastMessage("Error updating profile.");
    setShowToast(true);
  }

  const shipAddressSuccessMsg = () => {
    setToastBg("success");
    setToastMessage("Shipping address added succesfully.");
    setShowToast(true);
  }

  const shipAddressErrorMsg = () => {
    setToastBg("danger");
    setToastMessage("Error adding shipping address.");
    setShowToast(true);
  };

  const getUserProfile = () => {
    fetch("/api/user", {
      method: "GET",
      headers: {
        Authorization: `kaToken ${token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        history.push("/login");
        throw res;
      })
      .then((data) => {
        setUser(data);
      });
  };

  const getShippingAddresses = () => {
    fetch('/api/ship-addr', {
      method: 'GET',
      headers: {
        Authorization: `kaToken ${token}`,
      },
    })
    .then( (res) => {
      if (res.ok) return res.json()
    })
    .then( (data) => {
      setAddresses(data.data)
    })
  }

  useEffect(() => {
    getUserProfile();
    getShippingAddresses();
  }, []);

  const toggleMode = () => {
    setDisabled(!disabled);
    disabled ? setButtonText("Save Profile") : setButtonText("Edit Profile");

    if (!disabled) updateProfile();
  };

  const updateProfile = () => {
    const data = {
      username: firstNameRef.current.value,
      userlastname: lastNameRef.current.value,
      // email: emailRef.current.value,
      company: companyNameRef.current.value,
      address: addressRef.current.value,
      city: cityRef.current.value,
      state: stateRef.current.value,
      zip: zipRef.current.value,
      country: countryRef.current.value,
      phone: phoneRef.current.value
    }

    fetch("/api/user", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `kaToken ${token}`,
      },
      body: JSON.stringify(data)
    })  
    .then( res => {
      if (res.ok) return res.json();
      updProfileErrorMsg();
    })
    .then( data => {
      if (data.code && data.code === 200) {
        updProfileSuccessMsg();
      } else {
        updProfileErrorMsg();
      }
    })
  }

  const handleSetPasswordClick = (e) => {
    e.preventDefault();
    setShowPasswordModal(true);
  };

  const handlePasswordSubmit = (id, password) => {
    setShowPasswordModal(false);

    fetch("/api/user", {
      method: "PUT",
      headers: {
        Authorization: `kaToken ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({password: password}),
    })
      .then((res) => {
        if (res.ok) return res.json();
        pwdErrorMsg();
      })
      .then((data) => {
        if (data.code && data.code === 200) {
          pwdSuccessMsg();
        } else pwdErrorMsg();
      });
  };

  return (
    <>
      <Page title="Profile">
        <Form>
          <Card className="my-3">
            <Card.Header
              className="d-flex align-items-baseline"
              style={{ backgroundColor: "#4d5b7e" }}
            >
              <h5 className="text-white">User</h5>

              <Button
                className="ms-auto d-flex align-items-baseline"
                variant="outline-light"
                onClick={handleSetPasswordClick}
              >
                <span className="material-icons me-3">key</span>
                <span>Set Password</span>
              </Button>
            </Card.Header>

            <Card.Body>
              <Row>
                <Form.Group className="mb-3 col-md-6">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={30}
                    ref={firstNameRef}
                    defaultValue={user.username}
                    disabled={disabled}
                  />
                </Form.Group>

                <Form.Group className="mb-3 col-md-6">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={30}
                    ref={lastNameRef}
                    defaultValue={user.userlastname}
                    disabled={disabled}
                  />
                </Form.Group>
              </Row>

              <Row>
                <Form.Group className="mb-3 col-md-6">
                  <Form.Label>email</Form.Label>
                  <Form.Control
                    type="email"
                    required
                    maxLength={255}
                    ref={emailRef}
                    defaultValue={user.email}
                    disabled
                  />
                </Form.Group>

                <Form.Group className="mb-3 col-md-6">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={16}
                    ref={phoneRef}
                    disabled={disabled}
                    defaultValue={user.phone}
                  />
                </Form.Group>
              </Row>
            </Card.Body>
          </Card>

          <Card className="my-3">
            <Card.Header
              className="d-flex align-items-baseline"
              style={{ backgroundColor: "#4d5b7e" }}
            >
              <h5 className="text-white">Company</h5>

              
            </Card.Header>

            <Card.Body>
              <Row>
                <Form.Group className="mb-3 col-md-6">
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={30}
                    ref={companyNameRef}
                    defaultValue={user.company}
                    disabled={disabled}
                  />
                </Form.Group>

                <Form.Group className="mb-3 col-md-6">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={30}
                    ref={addressRef}
                    defaultValue={user.address}
                    disabled={disabled}
                  />
                </Form.Group>
              </Row>

              <Row>
                <Form.Group className="mb-3 col-md-3">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={30}
                    ref={cityRef}
                    disabled={disabled}
                    defaultValue={user.city}
                  />
                </Form.Group>

                <Form.Group className="mb-3 col-md-3">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={30}
                    ref={stateRef}
                    disabled={disabled}
                    defaultValue={user.state}
                  />
                </Form.Group>

                <Form.Group className="mb-3 col-md-3">
                  <Form.Label>Zip</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={5}
                    ref={zipRef}
                    disabled={disabled}
                    defaultValue={user.zip}
                  />
                </Form.Group>

                <Form.Group className="mb-3 col-md-3">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    required
                    maxLength={30}
                    ref={countryRef}
                    disabled={disabled}
                    defaultValue={user.country}
                  />
                </Form.Group>
              </Row>
            </Card.Body>
          </Card>
        </Form>

        <ShippingAddressList 
          ShippingAddresses={addresses} 
          onAddressAdded={
            ()=> { 
              shipAddressSuccessMsg(); 
              getShippingAddresses();
            }
          }
          onError={
            ()=>shipAddressErrorMsg()
          }
        />

        <Stack direction="horizontal">
          <Button className="ms-auto" onClick={toggleMode} style={{marginBottom: '60px'}}>
            {buttonText}
          </Button>
        </Stack>
      </Page>

      <PasswordModal
        show={showPasswordModal}
        onCancel={() => {
          setShowPasswordModal(false);
        }}
        onSubmit={handlePasswordSubmit}
      />

      <ToastContainer className="p-3" position="middle-center" containerposition='fixed'>
        <Toast
          show={showToast}
          bg={toastBg}
          delay={2000}
          autohide
          onClose={() => setShowToast(false)}
        >
          <Toast.Body className="text-white text-center">
            {toastMessage}
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default ProfilePage;
