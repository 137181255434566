import React from 'react';
import {
    PDFViewer,
} from '@react-pdf/renderer';
import {
    Button
} from 'react-bootstrap';
import RmaPdf from './RmaPdf';





const RmaPdfView = ({rma, address, barcode, onClose}) => {

    return(
        <>
            <PDFViewer style={{width: "100%", height:"calc(100vh - 130px)"}}>
                <RmaPdf rma={rma} address={address} barcode={barcode}/>
            </PDFViewer>

            <div className="d-flex justify-content-end">
                <Button variant="danger" className="ms-2" type="cancel" onClick={onClose} style={{position: 'absolute', top:'80px', left: 'calc(100vw - 25% - 90px)'}}>
                    <span className="material-icons-outlined fs-6 me-2">close</span>
                    Close
                </Button>
            </div>
        </>


    )
}

export default RmaPdfView;